import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGroup, Input, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import Loader from "../../UI/Loader/Loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { confirmAlert } from "react-confirm-alert";
import "./NotePage.scss";
import { IoIosDocument } from "react-icons/io";

class NotePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      note: null,
      loading: true,
      changingTitle: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.stepSubId) {
      this.getNote();
    }
  }

  componentDidUpdate() {
    if (this.state.note && this.props.match.params.stepSubId !== this.state.note.id) {
      this.props.saveNote(this.state.note);
      clearTimeout(this.saveTimer);
      this.getNote();
      this.setState({ note: null, loading: true });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.saveTimer);
    if (!this.dontSaveNote) {
      this.props.saveNote(this.state.note);
    }
  }

  fieldUpdateHandler = (event) => {
    const note = { ...this.state.note, [event.target.id]: event.target.value };
    this.updateHandler(note);
  };

  documentUpdateHandler = (event, editor) => {
    const data = editor.getData();
    const note = { ...this.state.note, content: data };
    this.updateHandler(note);
  };

  updateHandler = (note, rightAway = false) => {
    this.setState({ note });
    const sameTitle = this.props.notes.filter((n) => n.title.toLowerCase() === note.title.toLowerCase());
    if (sameTitle.length > 0 && sameTitle[0].id !== note.id) {
      toast.warning("There's already a note with this title!");
      return;
    }
    clearTimeout(this.saveTimer);
    if (rightAway) {
      this.props.saveNote(note);
    } else {
      this.saveTimer = setTimeout(() => {
        this.props.saveNote(note);
      }, 1000);
    }
  };

  getNote = () => {
    const noteIndex = this.props.notes.findIndex((n) => n.id === this.props.match.params.stepSubId);
    if (noteIndex > -1) {
      this.setState({
        note: JSON.parse(JSON.stringify(this.props.notes[noteIndex])),
        loading: false,
      });
    } else {
      this.setState({ loading: false });
      toast.warning("technical error");
    }
  };

  deleteHandler = () => {
    confirmAlert({
      title: "Really delete note?",
      message: "This cannot be undone",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            clearTimeout(this.saveTimer);
            const newNotes = this.props.notes.filter((n) => n.id !== this.state.note.id);
            this.props.saveNotes(newNotes);
            this.dontSaveNote = true;
            this.props.history.push(`/project/${this.props.project.id}`);
            toast.warning("Note deleted");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  render() {
    const { note, loading, dropdownOpen, changingTitle } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (!note) {
      return (
        <div>
          <h2>Error: Can't find requested note</h2>
        </div>
      );
    }

    return (
      <div className="notepage">
        <div className="notepage__header">
          <IoIosDocument />
          {changingTitle ? (
            <FormGroup>
              <Input
                type="text"
                name="title"
                bsSize="lg"
                id="title"
                placeholder="Note title (short description)"
                value={note.title}
                onChange={this.fieldUpdateHandler}
              />
            </FormGroup>
          ) : (
            <h2>{note.title}</h2>
          )}

          <Dropdown isOpen={dropdownOpen} toggle={() => this.setState({ dropdownOpen: !dropdownOpen })}>
            <DropdownToggle>Options</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.setState({ changingTitle: !changingTitle })}>
                {changingTitle ? "Done changing title" : "Change title"}
              </DropdownItem>
              <DropdownItem onClick={this.deleteHandler}>Delete note</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        <CKEditor
          editor={ClassicEditor}
          data={note.content || ""}
          className="main-editor"
          config={{
            toolbar: {
              items: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "blockQuote",
                "insertTable",
                "|",
                "undo",
                "redo",
              ],
            },
            table: {
              contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
            },
            language: "en",
          }}
          /*
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}*/
          onChange={this.documentUpdateHandler} /*
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}*/
        />
      </div>
    );
  }
}

export default withRouter(NotePage);

import React, { useState, useRef } from "react";
import { FormGroup, Label, Input, Form, Button } from "reactstrap";
import { RiDeleteBack2Line } from "react-icons/ri";

const CheckList = ({ json, storyJsonUpdateHandler }) => {
  const [newItem, setNewItem] = useState("");
  const inputEl = useRef(null);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!newItem) {
      return;
    }

    const newCheckList = json.checkList ? [...json.checkList] : [];
    newCheckList.push({ label: newItem, checked: false });
    updateHandler(newCheckList);

    setNewItem("");
    inputEl.current.focus();
  };

  const toggleItem = (index) => {
    const newCheckList = [...json.checkList];
    newCheckList[index].checked = !newCheckList[index].checked;
    updateHandler(newCheckList);
  };

  const delItem = (index) => {
    const newCheckList = [...json.checkList];
    newCheckList.splice(index, 1);
    updateHandler(newCheckList);
  };

  const updateHandler = (checkList) => {
    const newJson = {
      ...json,
      checkList,
    };
    storyJsonUpdateHandler(newJson, true);
  };

  return (
    <div className="story__checklist">
      <h3>Checklist</h3>
      {json &&
        json.checkList &&
        json.checkList.map((item, index) => (
          <div key={index} className="checklist__item">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => toggleItem(index)}
                />{" "}
                {item.label}
              </Label>
            </FormGroup>
            <div className="checklist__del" onClick={() => delItem(index)}>
              <RiDeleteBack2Line color="black" />
            </div>
          </div>
        ))}
      {(!json || !json.checkList || json.checkList.length === 0) && (
        <p>Maybe add some checklist items?</p>
      )}
      <Form onSubmit={submitHandler} className="new-story__form">
        <FormGroup>
          <Input
            type="text"
            name="newStoryText"
            id="newStoryText"
            ref={inputEl}
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
          />
        </FormGroup>
        <Button color="primary">Add</Button>
      </Form>
    </div>
  );
};

export default CheckList;

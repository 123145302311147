import React, { useState } from "react";
import { toast } from "react-toastify";
import { Alert, Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import AppBody from "../UI/AppBody/AppBody";
import "./Pro.scss";
import Loader from "../UI/Loader/Loader";
import { withRouter } from "react-router-dom";
import { firestore } from "../../firebase";

const Pro = ({ user, proSuccess, membership, history }) => {
  const [loading, setLoading] = useState(false);
  if (membership === "pro" && !proSuccess) {
    return (
      <AppBody>
        <div className="pro">
          <h3>You already have a Pro membership!</h3>
        </div>
      </AppBody>
    );
  }

  const goHandler = async () => {
    setLoading(true);
    const docRef = await firestore
      .collection("users")
      .doc(user.id)
      .collection("checkout_sessions")
      .add({
        price: "price_1LxxH5HQGmOTnE3d2XB9K56P",
        success_url: `${window.location.origin}/pro/success`,
        cancel_url: `${window.location.origin}/pro`,
        allow_promotion_codes: true,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const currentPlan = user?.data?.source && user?.data?.source === "regular" ? "Free" : user?.data?.source;

  let proContent;

  if (loading) {
    proContent = <Loader />;
  } else if (proSuccess) {
    proContent = (
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">One Week Leap PRO activated!</h4>
          <p className="card-text">Congratulations on going Pro! You&apos;ve officially upped your game!</p>
          <Button onClick={() => history.push("/projects")}>Go to your projects</Button>
        </div>
      </div>
    );
  } else {
    proContent = (
      <>
        <Button color="primary" onClick={goHandler}>
          Pay with Stripe
        </Button>
        <p>
          <strong>Don't forget your discountcode! OWLLIMITED</strong>
        </p>
      </>
    );
  }

  return (
    <AppBody>
      <div className="pro">
        <h1>One Week Leap Pro!</h1>
        <br />
        <div className="card-deck mb-3 text-center">
          <Card className="box-shadow">
            <CardHeader>
              <h4>Current plan</h4>
            </CardHeader>
            <CardBody>
              <h3 className="card-title" style={{ textTransform: "capitalize " }}>
                {currentPlan}
              </h3>
              <h4>Free</h4>
              <h5>
                {`${user?.maxProjects || 1} ${user?.maxProjects > 1 ? "projects" : "project"}`}
                {Number(user?.data?.maxProjects) > 1 ? "s" : ""}
              </h5>
            </CardBody>
          </Card>
          <Card className="box-shadow">
            <CardHeader>
              <h4>One Week Leap PRO</h4>
            </CardHeader>
            <CardBody>
              <h3 className="card-title">
                <span style={{ textDecoration: "line-through" }}>$ 49,99</span>{" "}
                <span style={{ fontSize: "15px", textDecoration: "unset" }} className="text-muted">
                  {" "}
                  / year
                </span>
              </h3>
              <h5>Unlimited projects</h5>
              <br />
              <Alert color="primary">One time offering (limited)</Alert>
              <Table bordered>
                <tbody>
                  <tr>
                    <td>OWL-PRO 1 year</td>
                    <td style={{ textAlign: "right" }}>
                      <span style={{ textDecoration: "line-through" }}>$ 49,99</span>
                    </td>
                  </tr>
                  <tr style={{ color: "red" }}>
                    <td>Vouchercode: OWLLIMITED</td>
                    <td style={{ textAlign: "right" }}>
                      <span>- $ 29,99</span>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ textAlign: "right" }}>
                      <span style={{ fontWeight: "bold" }}>$ 19,99</span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              {proContent}
            </CardBody>
          </Card>
        </div>
      </div>
    </AppBody>
  );
};

export default withRouter(Pro);

import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import NewStory from "../UI/NewStory/NewStory";
import Story from "../UI/Story/Story";
import { Row, Col } from "reactstrap";
import Explainer from "../UI/Explainer/Explainer";
import { Link } from "react-router-dom";

class Prioritize extends Component {
  constructor(props) {
    super(props);
    this.grid = 8;
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#e9ecef",
  });

  onDragEnd = (result) => {
    const { project } = this.props;
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const newGroups = { ...project.json };
    let sourceGroup = newGroups[source.droppableId];

    if (source.droppableId === destination.droppableId) {
      sourceGroup = this.reorder([...sourceGroup], source.index, destination.index);
      newGroups[source.droppableId] = sourceGroup;
    } else {
      if (!newGroups[destination.droppableId]) {
        newGroups[destination.droppableId] = [];
      }

      let destGroup = newGroups[destination.droppableId];
      const result = this.move([...sourceGroup], [...destGroup], source, destination);

      newGroups[source.droppableId] = result[source.droppableId];
      newGroups[destination.droppableId] = result[destination.droppableId];
    }
    this.props.saveProject({ ...project, json: newGroups });
  };

  getColumn = (group, key) => {
    return (
      <div className="itemlist" key={key}>
        <Droppable droppableId={key}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={this.getListStyle(snapshot.isDraggingOver)}
            >
              {group.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="itemlist__item"
                      style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      {
                        <Story
                          story={item}
                          project={this.props.project}
                          participants={this.props.participants}
                          stories={this.props.stories}
                          saveProject={this.props.saveProject}
                          user={this.props.user}
                          saveStory={this.props.saveStory}
                        />
                      }
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  render() {
    const { project, saveProject, stories } = this.props;

    const backlog =
      project.json && project.json.backlog
        ? project.json.backlog.map((storyId) => stories.find((story) => story.id === storyId))
        : [];

    return (
      <div className="overview">
        <h2>Prioritize the Backlog</h2>
        <Row>
          <Col lg="8">
            <DragDropContext onDragEnd={this.onDragEnd}>
              {this.getColumn(backlog, "backlog")}
              <NewStory onSubmit={saveProject} project={project} stories={stories} />
            </DragDropContext>
          </Col>
          <Col lg="4">
            <Explainer>
              <p>
                <strong>Let's Drag and Drop!</strong>
              </p>
              <p>
                Now that we've got a pretty decent backlog of tasks, drag and drop them in the right order. The most
                important tasks need to be on top and the least important on the bottom.
              </p>
              <p>Look for dependencies. Are there any tasks that need to be done first?</p>
              <p>
                We've already pre-sorted your tasks by MoScoW designation. So try to keep Must have's on top and Would
                like to Haves on bottom.
              </p>
              <p>
                Thought of a new task? You can add it here if you like. But maybe go back to steps 2 - 5 for any new
                tasks you create.
              </p>
              <p>Happy dragging!</p>
              <p>
                Done? Congratulations! You should now have a proper task backlog and you're ready to start your first
                One Week Leap! Lets go to the Project Overview page and drag some tasks to your first One Week Leap!
              </p>
              <Link className="btn btn-primary" to={`/project/${project.id}/overview`}>
                {"➜ Project overview"}
              </Link>
            </Explainer>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Prioritize;

import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Story from "../UI/Story/Story";
import { FormGroup, Input, Button } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import NewStory from "../UI/NewStory/NewStory";

class OWL extends Component {
  constructor(props) {
    super(props);
    this.grid = 8;
    this.state = {
      todo: [],
      doing: [],
      done: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const owl =
      props.project.json && props.project.json.owl
        ? props.project.json.owl.map((storyId) => props.stories.find((story) => story.id === storyId))
        : [];

    const newState = {
      todo: owl.filter((item) => !item.json.status || item.json.status === "todo"),
      doing: owl.filter((item) => item.json.status === "doing"),
      done: owl.filter((item) => item.json.status === "done"),
    };
    return newState;
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#e9ecef",
  });

  onDragEnd = (result) => {
    const { project, stories, user } = this.props;
    const { source, destination } = result;

    const story = {
      ...stories.find((story) => story.id === this.state[source.droppableId][source.index].id),
    };

    // dropped outside the list
    if (!destination) {
      return;
    }
    const newGroups = { ...this.state };
    let sourceGroup = newGroups[source.droppableId];

    if (source.droppableId === destination.droppableId) {
      sourceGroup = this.reorder([...sourceGroup], source.index, destination.index);
      newGroups[source.droppableId] = sourceGroup;
    } else {
      if (!newGroups[destination.droppableId]) {
        newGroups[destination.droppableId] = [];
      }

      let destGroup = newGroups[destination.droppableId];
      const result = this.move([...sourceGroup], [...destGroup], source, destination);

      newGroups[source.droppableId] = result[source.droppableId];
      newGroups[destination.droppableId] = result[destination.droppableId];

      story.json.status = destination.droppableId;

      if (source.droppableId === "todo" && destination.droppableId === "doing") {
        story.json.person = user.id;
      }

      this.props.saveStory(story);
    }

    const newOwl = newGroups.doing
      .map((item) => item.id)
      .concat(newGroups.todo.map((item) => item.id))
      .concat(newGroups.done.map((item) => item.id));

    const projectJson = { ...project.json, owl: newOwl };

    this.props.saveProject({ ...project, json: projectJson });
  };

  getColumn = (group, key) => {
    return (
      <div className="itemlist" key={key}>
        <Droppable droppableId={key}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={this.getListStyle(snapshot.isDraggingOver)}
            >
              {group.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="itemlist__item"
                      style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      {
                        <Story
                          story={item}
                          project={this.props.project}
                          participants={this.props.participants}
                          stories={this.props.stories}
                          saveProject={this.props.saveProject}
                          user={this.props.user}
                          saveStory={this.props.saveStory}
                        />
                      }
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  fieldUpdateHandler = (event) => {
    const json = {
      ...this.props.project.json,
      [event.target.id]: event.target.value,
    };
    const project = { ...this.props.project, json };

    this.props.saveProjectDelay(project);
  };

  updateHandler = (story, rightAway = false) => {
    this.setState({ story });
    clearTimeout(this.saveTimer);
    clearTimeout(this.getTimer);
    this.getTimer = setTimeout(this.getStory, 15000);
    if (rightAway) {
      this.props.saveStory(story);
    } else {
      this.saveTimer = setTimeout(() => {
        this.props.saveStory(story);
      }, 5000);
    }
  };

  finishHandler = () => {
    const { project, stories } = this.props;

    confirmAlert({
      title: "One Week Leap Finish",
      message:
        "Congrats on finishing your OWL! By finishing this you will automatically start a new OWL. Any tasks in your 'To do' or 'Doing columns will move to the new OWL. Shall we continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const finishedOwlStories = project.json.owl.filter((storyId) => {
              const story = {
                ...stories.find((story) => story.id === storyId),
              };
              return story?.json?.status && story.json.status === "done";
            });

            const newDone = [...project.json.done, ...finishedOwlStories];
            const newOwl = project.json.owl.filter((storyId) => !finishedOwlStories.includes(storyId));

            const oldOwls = project.json.oldOwls ? project.json.oldOwls : [];

            oldOwls.push({
              finishDate: new Date(),
              weekGoal: project.json.weekGoal || "",
            });

            const projectJson = {
              ...project.json,
              owl: newOwl,
              oldOwls,
              done: newDone,
              weekGoal: "",
            };
            this.props.saveProject({ ...project, json: projectJson });

            setTimeout(() => {
              this.props.history.push(`/project/${project.id}`);
              toast.success("One week leap finished! Awesome!");
            }, 200);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  render() {
    const { todo, doing, done } = this.state;
    const { project, stories, saveProject } = this.props;

    return (
      <div className="owl">
        <div className="owl__columns">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="owl__column">
              <h3>To do</h3>
              {this.getColumn(todo, "todo")}
              <NewStory onSubmit={saveProject} project={project} stories={stories} owl />
            </div>
            <div className="owl__column">
              <h3>Doing</h3>
              {this.getColumn(doing, "doing")}
            </div>
            <div className="owl__column">
              <h3>Done</h3>
              {this.getColumn(done, "done")}
            </div>
          </DragDropContext>
        </div>
        <br />
        <h3>This weeks main goal</h3>
        <FormGroup>
          <Input
            type="textarea"
            name="weekGoal"
            id="weekGoal"
            value={project && project.json && project.json.weekGoal ? project.json.weekGoal : ""}
            onChange={this.fieldUpdateHandler}
          />
        </FormGroup>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button color="primary" onClick={this.finishHandler}>
            Finish this OWL!
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(OWL);

import React from "react";
import RangeStepInput from "./RangeStep/RangeStep";
import { Row, Col } from "reactstrap";
import Explainer from "../UI/Explainer/Explainer";
import { Link } from "react-router-dom";

const StoryTime = ({ stories, project, saveStory, saveProject }) => {
  const backlog =
    project.json && project.json.backlog
      ? project.json.backlog.map((storyId) => stories.find((story) => story.id === storyId))
      : [];

  return (
    <div className="complexity">
      <h2>Step 5: Completion-time estimation</h2>
      <Row>
        <Col lg="8">
          <div className="greybox">
            {backlog.map((story) => (
              <RangeStepInput
                stepLabel="hours"
                key={story.id}
                story={story}
                saveStory={saveStory}
                stories={stories}
                project={project}
                saveProject={saveProject}
                min={0}
                max={9}
                smallerBigger={true}
                breakPoint={4}
                defaultValue={2}
              />
            ))}
          </div>
        </Col>
        <Col lg="4">
          <Explainer>
            <p>
              <strong>Lets speed things up!</strong>
            </p>
            <p>Estimate the time (hours) you think it will probably take to fully complete each task.</p>
            <p>
              If you think a task will take 5 or more hours you'll get the option to split the task into multiple
              smaller tasks. The goal is to have tasks that are doable in one worksitting of max 4 hours.
            </p>
            <p>Tasks that you haven't yet estimated are blue!</p>
            <p>Done? Then lets take a look at task dependencies in step 6!</p>
            <Link className="btn btn-primary" to={`/project/${project.id}/dependencies`}>
              {"➜ Step 6: Dependencies"}
            </Link>
          </Explainer>
        </Col>
      </Row>
    </div>
  );
};

export default StoryTime;

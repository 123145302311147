import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import "./NewStory.scss";
import { initialStory } from "../../../lib/Variables";
import { v4 as uuidv4 } from "uuid";

const NewStory = ({ onSubmit, project, stories, owl = false }) => {
  const [newStoryText, setNewStoryText] = useState("");
  const [busy, setBusy] = useState(false);
  const inputEl = useRef(null);

  const submitHandler = (e) => {
    e.preventDefault();
    if (busy) {
      return;
    }

    if (!newStoryText) {
      toast.warning("Enter a 1 sentence description of the new task");
      return;
    }

    const newId = uuidv4();

    const newStory = {
      id: newId,
      project: project.id,
      json: {
        ...initialStory,
        name: newStoryText,
        document: project.json.defaultText || "",
      },
    };

    const newStories = [...stories];
    newStories.push(newStory);
    const json = { ...project.json };
    if (owl) {
      if (!json.owl) {
        json.owl = [];
      }
      json.owl.push(newId);
    } else {
      if (!json.backlog) {
        json.backlog = [];
      }
      json.backlog.push(newId);
    }

    const newProject = { ...project, json };

    setNewStoryText("");
    inputEl.current.focus();
    onSubmit(newProject, newStories);
  };

  return (
    <div className="new-story">
      <Label for="newStory">Add new task</Label>
      <Form onSubmit={submitHandler} className="new-story__form">
        <FormGroup>
          <Input
            type="text"
            name="newStoryText"
            id="newStoryText"
            ref={inputEl}
            value={newStoryText}
            onChange={(e) => setNewStoryText(e.target.value)}
          />
        </FormGroup>
        {busy ? (
          <Spinner color="primary" />
        ) : (
          <Button color="primary">Add task</Button>
        )}
      </Form>
    </div>
  );
};

export default NewStory;

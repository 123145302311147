import React, { useState } from "react";
import { FormGroup, Label, Input, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, CustomInput } from "reactstrap";
import { MdSettings } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { DepSelect } from "../Dependencies/Dependencies";
import { FaFlag } from "react-icons/fa";

const StorySettings = ({ story, updateHandler, history, project, saveProject, participants, stories }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [complexity, setComplexity] = useState(story.json.complexity || 5);
  const setComplexityValue = (e) => {
    setComplexity(e.target.value);
  };
  const saveComplexity = () => {
    const json = {
      ...story.json,
      complexity,
    };
    updateHandler(json, true);
  };

  const [time, setTime] = useState(story.json.hours || 2);
  const setTimeValue = (e) => {
    setTime(e.target.value);
  };
  const saveTime = () => {
    const json = {
      ...story.json,
      hours: time,
    };
    updateHandler(json, true);
  };

  let timeLabel = time;
  if (parseInt(time) === 0) {
    timeLabel = `< ${parseInt(time) + 1}`;
  } else if (parseInt(time) === 9) {
    timeLabel = `> ${parseInt(time) - 1}`;
  }

  const fieldUpdateHandler = (event) => {
    const json = {
      ...story.json,
      [event.target.id]: event.target.value,
    };
    updateHandler(json, true);
  };

  const owlTodo =
    project.json && project.json.owl
      ? project.json.owl
          .map((storyId) => stories.find((s1) => s1.id === storyId))
          .filter((sf) => sf.json.status !== "done")
      : [];

  const inBacklog =
    project.json && project.json.backlog
      ? project.json.backlog.map((storyId) => stories.find((s1) => s1.id === storyId))
      : [];

  const depTotal = owlTodo.concat(inBacklog);

  const deleteHandler = () => {
    confirmAlert({
      title: "Really delete this task?",
      message: "This cannot be undone",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const owl =
              project.json && project.json.owl ? project.json.owl.filter((storyId) => storyId !== story.id) : [];

            const done =
              project.json && project.json.done ? project.json.done.filter((storyId) => storyId !== story.id) : [];

            const backlog =
              project.json && project.json.backlog
                ? project.json.backlog.filter((storyId) => storyId !== story.id)
                : [];

            const json = {
              ...project.json,
              owl,
              done,
              backlog,
            };

            saveProject({ ...project, json });

            setTimeout(() => {
              history.push(`/project/${story.project}`);
              toast.warning("Task deleted");
            }, 200);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const flagHandler = () => {
    const json = { ...story.json, flag: !story.json.flag };
    updateHandler(json, true);
  };

  return (
    <div className="story__settings">
      <div className="story__settings-header">
        <h4>Settings</h4>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className="settings-button" color="link">
            <MdSettings />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={flagHandler}>{story.json.flag ? "Remove flag" : "Flag this task"}</DropdownItem>
            <DropdownItem onClick={deleteHandler}>Delete this task</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      {story.json.flag && (
        <div className="story__settings-flag">
          <div className="story__icon">
            <FaFlag color="#dc3545" />
          </div>
          <span>This task is flagged</span>
        </div>
      )}
      <FormGroup>
        <Label for="status">Current status</Label>
        <Input
          type="select"
          name="status"
          id="status"
          value={story.json.status || "todo"}
          onChange={fieldUpdateHandler}
        >
          <option value="todo">To do</option>
          <option value="doing">Doing</option>
          <option value="done">Done</option>
        </Input>
      </FormGroup>
      {participants.lenght > 1 && (
        <FormGroup>
          <Label for="status">Assigned to</Label>
          <Input type="select" name="person" id="person" value={story.json.person || 0} onChange={fieldUpdateHandler}>
            {participants
              .filter((p) => !!p.userId)
              .map((person) => (
                <option key={person.userId} value={person.userId}>{`${person.displayName}`}</option>
              ))}
            <option value={0}>Not assigned</option>
          </Input>
        </FormGroup>
      )}

      <FormGroup>
        <Label for="status">Category</Label>
        <Input
          type="select"
          name="category"
          id="category"
          value={story.json.category || ""}
          onChange={fieldUpdateHandler}
        >
          {project.json?.categories?.map((cat) => (
            <option key={cat.name} value={cat.name}>
              {cat.name}
            </option>
          ))}
          <option value="">Not yet categorized</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="status">Priority (MoScoW)</Label>
        <Input type="select" name="moscow" id="moscow" value={story.json.moscow || ""} onChange={fieldUpdateHandler}>
          <option value="m">Must have</option>
          <option value="s">Should have</option>
          <option value="w">Would like to have</option>
          <option value="">Not yet prioritised</option>
        </Input>
      </FormGroup>
      <Label for="range-step-complexity">Complexity</Label>
      <div className="story__settings-range">
        <span>{story.json.complexity ? `${complexity}/10` : `Set complexity:`}</span>
        <div className="range-step-bar__input">
          <FormGroup>
            <CustomInput
              type="range"
              min={1}
              max={10}
              step={1}
              onChange={setComplexityValue}
              onMouseUp={saveComplexity}
              value={complexity}
              name="range-step-complexity"
              id={`range-step-complexity`}
            />
          </FormGroup>
        </div>
      </div>
      <br />
      <Label for="range-step-time">Completion time estimation</Label>
      <div className="story__settings-range">
        <span>{story.json.hours ? `${timeLabel}h` : `Set hours:`}</span>
        <div className="range-step-bar__input">
          <FormGroup>
            <CustomInput
              type="range"
              min={0}
              max={9}
              step={1}
              onChange={setTimeValue}
              onMouseUp={saveTime}
              value={time}
              name="range-step-time"
              id={`range-step-time`}
            />
          </FormGroup>
        </div>
      </div>
      <br />
      <Label for="range-step-time">Dependencies</Label>
      <DepSelect backLog={depTotal} story={story} saveStory={(newStory) => updateHandler(newStory.json, true)} />
    </div>
  );
};

export default withRouter(StorySettings);

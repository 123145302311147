import React, { Component } from "react";
import { toast } from "react-toastify";
import AppBody from "../UI/AppBody/AppBody";
import ProjectSideBar from "./ProjectSideBar";
import Loader from "../UI/Loader/Loader";
import { withRouter } from "react-router-dom";
import BreadCrumbs from "../UI/BreadCrumbs/BreadCrumbs";
import OverView from "./OverView";
import "./Project.scss";
import OWL from "./OWL";
import StoryPage from "./StoryPage/StoryPage";
import BrainDump from "./BrainDump";
import MoSCoW from "./MoSCoW";
import NotePage from "./NotePage/NotePage";
import ProjectSettings from "./ProjectSettings/ProjectSettings";
import Complexity from "./Complexity";
import StoryTime from "./StoryTime";
import Dependencies from "./Dependencies/Dependencies";
import Demotion from "./Demotion";
import Prioritize from "./Prioritize";
import ProjectSearch from "./ProjectSearch/ProjectSearch";
import ChatPage from "./ChatPage/ChatPage";
import { firestore } from "../../firebase";
import Categorize from "./Categorize";

class Project extends Component {
  constructor(props) {
    super(props);
    this.requestBusy = false;
    this.state = {
      loading: true,
      error: null,
      searchCommand: "",
      searchActive: 0,
    };
  }

  componentDidMount() {
    if (this.props.match.params.projectId) {
      this.getProject();
    } else {
      this.setState({
        error: "Oops!! No project found...",
        loading: false,
      });
    }
  }

  componentDidUpdate() {
    const { project } = this.state;

    if (project && this.props.match.params.projectId !== project.id) {
      this.unsubscribeProject();
      this.setState({
        loading: true,
        error: null,
        project: null,
        stories: null,
        notes: null,
        chatRooms: null,
      });
      this.getProject();
    }
    if (!this.props.match.params.step && project) {
      if (project.json && project.json && project.json.owl && project.json.owl.length > 0) {
        this.props.history.push(`/project/${this.props.match.params.projectId}/owl`);
      } else {
        this.props.history.push(`/project/${this.props.match.params.projectId}/overview`);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.saveTimer);
    if (this.unsubscribeProject) {
      this.unsubscribeProject();
    }
  }

  getProject = () => {
    this.unsubscribeProject = firestore.doc(`projects/${this.props.match.params.projectId}`).onSnapshot((doc) => {
      const project = doc.data();
      this.setState({
        project: { ...project, id: doc.id },
        stories: project.stories || [],
        notes: project.notes || [],
        chatRooms: project.chatRooms || [],
        loading: false,
        error: null,
      });

      if (!this.props.match.params.step) {
        if (project.json && project.json.owl && project.json.owl.length > 0) {
          this.props.history.push(`/project/${this.props.match.params.projectId}/owl`);
        } else {
          this.props.history.push(`/project/${this.props.match.params.projectId}/overview`);
        }
      }
    });
  };

  setSearchCommand = (searchCommand) => {
    this.setState({ searchCommand });
  };

  searchDeactivateHandler = () => {
    this.setState({
      searchCommand: "",
      searchActive: this.state.searchActive + 1,
    });
  };

  saveStoryHandler = (story) => {
    const stories = [...this.state.stories];
    stories[stories.findIndex((s) => s.id === story.id)] = story;

    firestore
      .doc(`projects/${this.props.match.params.projectId}`)
      .set({ stories }, { merge: true })
      .catch((error) => {
        toast.warning("Error: couldn't save task changes");
      });
  };

  saveNoteHandler = (note) => {
    const notes = [...this.state.notes];
    notes[notes.findIndex((n) => n.id === note.id)] = note;

    firestore
      .doc(`projects/${this.props.match.params.projectId}`)
      .set({ notes }, { merge: true })
      .catch((error) => {
        toast.warning("Error: couldn't save note changes");
      });
  };

  saveNotes = (notes) => {
    firestore
      .doc(`projects/${this.props.match.params.projectId}`)
      .set({ notes }, { merge: true })
      .catch((error) => {
        toast.warning("Error: couldn't save note changes");
      });
  };

  saveChatHandler = (chat) => {
    const chatRooms = [...this.state.chatRooms];
    chatRooms[chatRooms.findIndex((n) => n.id === chat.id)] = chat;

    this.setState({ chatRooms });

    firestore
      .doc(`projects/${this.props.match.params.projectId}`)
      .set({ chatRooms }, { merge: true })
      .catch((error) => {
        toast.warning("Error: couldn't save chatroom changes");
      });
  };

  saveChats = (chatRooms) => {
    this.setState({ chatRooms });

    firestore
      .doc(`projects/${this.props.match.params.projectId}`)
      .set({ chatRooms }, { merge: true })
      .catch((error) => {
        toast.warning("Error: couldn't save chatroom changes");
      });
  };

  saveProjectHandler = (project, stories) => {
    if (stories) {
      this.setState({ project, stories });
      firestore
        .doc(`projects/${this.props.match.params.projectId}`)
        .set({ ...project, stories }, { merge: true })
        .catch((error) => {
          toast.warning("Error: couldn't save project");
        });
    } else {
      this.setState({ project });
      firestore
        .doc(`projects/${this.props.match.params.projectId}`)
        .set({ ...project }, { merge: true })
        .catch((error) => {
          toast.warning("Error: couldn't save project");
        });
    }
  };

  saveProjectDelayHandler = (project, rightAway = false) => {
    this.setState({ project });
    clearTimeout(this.saveTimer);
    if (rightAway) {
      this.saveProjectHandler(project);
    } else {
      this.saveTimer = setTimeout(() => {
        this.saveProjectHandler(project);
      }, 1000);
    }
  };

  render() {
    const { error, loading, project, stories, notes, searchCommand, searchActive, chatRooms } = this.state;
    const step = this.props.match.params.step;
    const stepSubId = this.props.match.params.stepSubId;

    if (loading) {
      return (
        <>
          <ProjectSideBar loading />
          <AppBody>
            <Loader />
          </AppBody>
        </>
      );
    }

    if (error) {
      return (
        <>
          <ProjectSideBar />
          <AppBody>
            <h2>{error}</h2>
          </AppBody>
        </>
      );
    }

    let DynamicComponent = OverView;
    let stepLabel = "Overview";

    if (step === "owl") {
      DynamicComponent = OWL;
      stepLabel = "One Week Leap";
    } else if (step === "task") {
      DynamicComponent = StoryPage;
      stepLabel = "Task details";
    } else if (step === "note") {
      DynamicComponent = NotePage;
      stepLabel = "Note";
    } else if (step === "settings") {
      DynamicComponent = ProjectSettings;
      stepLabel = "Settings";
    } else if (step === "braindump") {
      DynamicComponent = BrainDump;
      stepLabel = "Brain-dump";
    } else if (step === "categorize") {
      DynamicComponent = Categorize;
      stepLabel = "Categorize";
    } else if (step === "moscow") {
      DynamicComponent = MoSCoW;
      stepLabel = "MoScoW";
    } else if (step === "complexity") {
      DynamicComponent = Complexity;
      stepLabel = "Complexity";
    } else if (step === "time") {
      DynamicComponent = StoryTime;
      stepLabel = "Completion time";
    } else if (step === "dependencies") {
      DynamicComponent = Dependencies;
      stepLabel = "Dependencies";
    } else if (step === "demotion") {
      DynamicComponent = Demotion;
      stepLabel = "Demotion";
    } else if (step === "prioritize") {
      DynamicComponent = Prioritize;
      stepLabel = "Prioritize";
    } else if (step === "chat") {
      DynamicComponent = ChatPage;
      stepLabel = "Chat";
    }

    /*
    const chips = stories
      ? [...new Set(stories.map((item) => item.json.chips))]
          .flat(1)
          .concat(project.json && project.json.chips ? project.json.chips : [])
          .filter((v, i, a) => a.indexOf(v) === i)
          .filter((c) => c)
      : null;
      */
    const chips = project.json && project.json.chips ? project.json.chips : [];

    return (
      <>
        <ProjectSideBar
          project={project}
          stories={stories}
          step={step}
          stepSubId={stepSubId}
          notes={notes}
          saveNotes={this.saveNotes}
          saveChats={this.saveChats}
          user={this.props.user}
          participants={project.participants}
          saveProject={this.saveProjectHandler}
          saveStory={this.saveStoryHandler}
          setSearchCommand={this.setSearchCommand}
          searchActive={searchActive}
          chatRooms={chatRooms}
        />
        {searchCommand && (
          <ProjectSearch
            searchCommand={searchCommand}
            project={project}
            stories={stories}
            deactivate={this.searchDeactivateHandler}
            participants={project.participants}
            saveProject={this.saveProjectHandler}
            user={this.props.user}
            saveStory={this.saveStoryHandler}
          />
        )}
        <AppBody>
          <BreadCrumbs
            crumbs={[
              { title: "Projects", link: "/projects" },
              {
                title: project?.name?.charAt(0).toUpperCase() + project?.name?.slice(1),
                link: `/project/${project.id}/overview`,
              },
              { title: stepLabel, active: true },
            ]}
          />
          <DynamicComponent
            project={project}
            stories={stories}
            saveProject={this.saveProjectHandler}
            saveProjectDelay={this.saveProjectDelayHandler}
            saveStory={this.saveStoryHandler}
            saveNote={this.saveNoteHandler}
            saveNotes={this.saveNotes}
            saveChat={this.saveChatHandler}
            saveChats={this.saveChats}
            chatRooms={chatRooms}
            participants={project.participants}
            notes={notes}
            chips={chips}
            user={this.props.user}
          />
        </AppBody>
      </>
    );
  }
}

export default withRouter(Project);

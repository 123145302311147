import React from "react";
import RangeStepInput from "./RangeStep/RangeStep";
import { Row, Col } from "reactstrap";
import Explainer from "../UI/Explainer/Explainer";
import { Link } from "react-router-dom";

const Complexity = ({ stories, project, saveStory, saveProject }) => {
  const backlog =
    project.json && project.json.backlog
      ? project.json.backlog.map((storyId) => stories.find((story) => story.id === storyId))
      : [];

  return (
    <div className="complexity">
      <h2>Step 4: Complexity</h2>
      <Row>
        <Col lg="8">
          <div className="greybox">
            {backlog.map((story) => (
              <RangeStepInput
                stepLabel="complexity"
                key={story.id}
                story={story}
                saveStory={saveStory}
                stories={stories}
                project={project}
                saveProject={saveProject}
                min={1}
                smallerBigger={false}
                max={10}
                breakPoint={6}
                defaultValue={5}
              />
            ))}
          </div>
        </Col>
        <Col lg="4">
          <Explainer>
            <p>
              <strong>Lets uncomplicate things!</strong>
            </p>
            <p>Score your tasks for complexity between 1 and 10; 1 meaning Dead simple, 10 meaning Drop Dead hard...</p>
            <p>
              If you score a task 7 or higher you'll get the option to split the task into multiple smaller tasks. The
              goal is to have as few as possible tasks with high complexity.
            </p>
            <p>Tasks that you haven't yet rated for complexity are blue!</p>
            <p>Done? Then lets estimate the completion time of each task in step 5!</p>
            <Link className="btn btn-primary" to={`/project/${project.id}/time`}>
              {"➜ Step 5: Completion time"}
            </Link>
          </Explainer>
        </Col>
      </Row>
    </div>
  );
};

export default Complexity;

/*
              .sort((a) => {
                if (!a.json.complexity) {
                  return -1;
                }
                return 0;
              }) */

import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Label, Input, Spinner, Alert } from "reactstrap";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { Link } from "react-router-dom";
import { signInWithFacebook, signInWithGoogle, auth } from "../../firebase";

const Login = ({ login }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState({});
  const [busy, setBusy] = useState(false);

  const loginHandler = (event) => {
    event.preventDefault();
    if (email.length > 0 && password.length > 0 && !busy) {
      setBusy(true);
      setFormError({});
      auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          login();
        })
        .catch((error) => {
          console.log(error);
          toast.warning(error?.message || "Error, can't login! Have you signed up already?");
          setBusy(false);
        });
    } else if (!email || !password) {
      toast.warning("Enter your email and password");
    }
  };

  return (
    <div className="login">
      <h2>Login</h2>
      <GoogleLoginButton onClick={() => signInWithGoogle()}>Login with Google</GoogleLoginButton>
      <FacebookLoginButton onClick={() => signInWithFacebook()}>Login with Facebook</FacebookLoginButton>
      <Form onSubmit={loginHandler}>
        <br />
        <p>
          <strong>Or login with username and password</strong>
        </p>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="E-mail address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        {formError.email ? <Alert color="warning">{formError.email}</Alert> : null}
        <FormGroup>
          <Label for="password">Password</Label>
          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroup>
        {formError.password ? <Alert color="warning">{formError.password}</Alert> : null}
        {busy ? <Spinner color="primary" /> : <Button color="primary">Login!</Button>}
        <Link to="/forgot-password">
          <Button color="link">Forgot password</Button>
        </Link>
      </Form>
    </div>
  );
};

export default Login;

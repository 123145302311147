import React, { useState, useEffect } from "react";
import "./SideBar.scss";
import { FiMenu } from "react-icons/fi";
import { withRouter } from "react-router-dom";

const SideBar = ({ children, title, location }) => {
  const [open, setOpen] = useState(false);
  const openToggle = () => setOpen((prevState) => !prevState);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div className={"sidebar" + (open ? " sidebar--open" : "")}>
      <div className="sidebar__inner">
        <div className="sidebar__top-bar">
          <h2>{title}</h2>
          <div className="sidebar__toggle" onClick={openToggle}>
            <FiMenu />
          </div>
        </div>
        <div className="sidebar__spacer" />
        {children}
      </div>
    </div>
  );
};

export default withRouter(SideBar);

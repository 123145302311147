import React, { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Spinner } from "reactstrap";
import { withRouter } from "react-router-dom";

const NewChat = ({ onSubmit, project, chats, history }) => {
  const [newChatText, setNewChatText] = useState("");
  const [busy, setBusy] = useState(false);
  const inputEl = useRef(null);

  const submitHandler = (e) => {
    e.preventDefault();
    if (busy) {
      return;
    }

    if (!newChatText) {
      toast.warning("Enter a title for this new channel");
      return;
    }

    if (
      chats.filter(
        (chat) => chat.title.toLowerCase() === newChatText.toLowerCase()
      ).length > 0
    ) {
      toast.warning("There's already a channel with this title!");
      return;
    }

    const newId = uuidv4();
    const newChat = {
      id: newId,
      title: newChatText,
      content: "",
      chat: [],
    };

    const newChats = [...chats];
    newChats.push(newChat);

    setNewChatText("");
    inputEl.current.focus();
    onSubmit(newChats);
    setTimeout(() => {
      history.push(`/project/${project.id}/chat/${newId}`);
    }, 200);
  };

  return (
    <div className="new-note">
      <Form onSubmit={submitHandler} className="new-note__form">
        <FormGroup>
          <Input
            type="text"
            name="newChatText"
            id="newChatText"
            ref={inputEl}
            value={newChatText}
            onChange={(e) => setNewChatText(e.target.value)}
          />
        </FormGroup>
        {busy ? (
          <Spinner color="primary" />
        ) : (
          <Button color="primary">Add channel</Button>
        )}
      </Form>
    </div>
  );
};

export default withRouter(NewChat);

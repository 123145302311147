import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import NewStory from "../UI/NewStory/NewStory";
import Story from "../UI/Story/Story";

class OverView extends Component {
  constructor(props) {
    super(props);
    this.grid = 8;
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#e9ecef",
  });

  onDragEnd = (result) => {
    const { project } = this.props;
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const newGroups = { ...project.json };
    let sourceGroup = newGroups[source.droppableId];

    if (source.droppableId === destination.droppableId) {
      sourceGroup = this.reorder([...sourceGroup], source.index, destination.index);
      newGroups[source.droppableId] = sourceGroup;
    } else {
      if (!newGroups[destination.droppableId]) {
        newGroups[destination.droppableId] = [];
      }

      let destGroup = newGroups[destination.droppableId];
      const result = this.move([...sourceGroup], [...destGroup], source, destination);

      newGroups[source.droppableId] = result[source.droppableId];
      newGroups[destination.droppableId] = result[destination.droppableId];
    }
    this.props.saveProject({ ...project, json: newGroups });
  };

  getColumn = (group, key) => {
    return (
      <div className="itemlist" key={key}>
        <Droppable droppableId={key}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={this.getListStyle(snapshot.isDraggingOver)}
            >
              {group.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="itemlist__item"
                      style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      {
                        <Story
                          story={item}
                          project={this.props.project}
                          participants={this.props.participants}
                          stories={this.props.stories}
                          saveProject={this.props.saveProject}
                          user={this.props.user}
                          saveStory={this.props.saveStory}
                        />
                      }
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  render() {
    const { project, saveProject, stories } = this.props;

    const owl =
      project.json && project.json.owl
        ? project.json.owl.map((storyId) => stories.find((story) => story.id === storyId))
        : [];

    const done =
      project.json && project.json.done
        ? project.json.done.map((storyId) => stories.find((story) => story.id === storyId))
        : [];

    const backlog =
      project.json && project.json.backlog
        ? project.json.backlog.map((storyId) => stories.find((story) => story.id === storyId))
        : [];

    return (
      <div className="overview">
        <div className="overview__columns">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="overview__column">
              <h2>Backlog</h2>
              {this.getColumn(backlog, "backlog")}
              <NewStory onSubmit={saveProject} project={project} stories={stories} />
              <h2>Finished</h2>
              {this.getColumn(done, "done")}
            </div>
            <div className="overview__column">
              <div className="overview__column__sticky">
                <h2>Current One Week Leap</h2>
                {this.getColumn(owl, "owl")}
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
    );
  }
}

export default OverView;

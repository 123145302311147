import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Story from "../UI/Story/Story";
import { Row, Col } from "reactstrap";
import { FaArrowDown } from "react-icons/fa";
import Explainer from "../UI/Explainer/Explainer";
import { Link } from "react-router-dom";

class Demotion extends Component {
  constructor(props) {
    super(props);
    this.grid = 8;
    this.state = {
      backlog: [],
      s: [],
      c: [],
      w: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const allStories =
      props.project.json && props.project.json.backlog
        ? props.project.json.backlog.map((storyId) => props.stories.find((story) => story.id === storyId))
        : [];

    const newState = {
      backlog: allStories.filter((item) => !item.json.moscow),
      m: allStories.filter((item) => item.json.moscow === "m"),
      s: allStories.filter((item) => item.json.moscow === "s"),
      w: allStories.filter((item) => item.json.moscow === "w"),
    };
    return newState;
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#e9ecef",
  });

  onDragEnd = (result) => {
    const { project, stories } = this.props;
    const { source, destination } = result;

    const story = {
      ...stories.find((story) => story.id === this.state[source.droppableId][source.index].id),
    };

    // dropped outside the list
    if (!destination) {
      return;
    }
    const newGroups = { ...this.state };
    let sourceGroup = newGroups[source.droppableId];

    if (source.droppableId === destination.droppableId) {
      sourceGroup = this.reorder([...sourceGroup], source.index, destination.index);
      newGroups[source.droppableId] = sourceGroup;
    } else {
      if (!newGroups[destination.droppableId]) {
        newGroups[destination.droppableId] = [];
      }

      let destGroup = newGroups[destination.droppableId];
      const result = this.move([...sourceGroup], [...destGroup], source, destination);

      newGroups[source.droppableId] = result[source.droppableId];
      newGroups[destination.droppableId] = result[destination.droppableId];

      story.json.moscow = destination.droppableId === "backlog" ? null : destination.droppableId;

      this.props.saveStory(story);
    }

    const newBacklog = newGroups.m
      .map((item) => item.id)
      .concat(newGroups.s.map((item) => item.id))
      .concat(newGroups.w.map((item) => item.id))
      .concat(newGroups.backlog.map((item) => item.id));

    const projectJson = { ...project.json, backlog: newBacklog };

    this.props.saveProject({ ...project, json: projectJson });
  };

  getColumn = (group, key) => {
    return (
      <div className="itemlist" key={key}>
        <Droppable droppableId={key}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={this.getListStyle(snapshot.isDraggingOver)}
            >
              {group.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="itemlist__item"
                      style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      {
                        <Story
                          story={item}
                          project={this.props.project}
                          participants={this.props.participants}
                          stories={this.props.stories}
                          saveProject={this.props.saveProject}
                          user={this.props.user}
                          saveStory={this.props.saveStory}
                        />
                      }
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  render() {
    const { m, s, w } = this.state;
    const { project } = this.props;

    return (
      <div className="moscow">
        <h2>Step 7: Demotion</h2>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Row>
            <Col lg="6">
              <h3>Must haves</h3>
              {this.getColumn(m, "m")}
              <div className="demoteArrow">
                <h3>Should haves</h3>
                <FaArrowDown size={50} />
              </div>

              {this.getColumn(s, "s")}
              <div className="demoteArrow">
                <h3>Would like to haves</h3>
                <FaArrowDown size={50} />
              </div>

              {this.getColumn(w, "w")}
            </Col>
            <Col lg="6">
              <Explainer>
                <p>
                  <strong>Lets be harsh!</strong>
                </p>
                <p>
                  We often overinflate the importance of tasks. In this screen, take another look at the tasks you've
                  created and really consider if they can be demoted.
                </p>
                <p>Is it really a Must have?</p>
                <p>Done? Then lets do some final prioritization in step 8!</p>
                <Link className="btn btn-primary" to={`/project/${project.id}/prioritize`}>
                  {"➜ Step 8: Prioritize"}
                </Link>
              </Explainer>
            </Col>
          </Row>
        </DragDropContext>
      </div>
    );
  }
}

export default Demotion;

import React from "react";

const AppBody = ({ children }) => {
  return (
    <div className="app-body__content">
      <div className="app-body__content-float">
        {children}
      </div>
    </div>
  );
};

export default AppBody;

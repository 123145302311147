import React from "react";
import { withRouter } from "react-router-dom";
import { Alert, Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import "./ProAdd.scss";

const ProAdd = ({ history }) => {
  return (
    <Card className="box-shadow proadd" inverse color="info" style={{ textAlign: "center" }}>
      <CardHeader>
        <h4>One Week Leap PRO</h4>
      </CardHeader>
      <CardBody>
        <Alert color="primary">
          Vouchercode:
          <br />
          <span style={{ fontWeight: "bold" }}>OWLLIMITED</span>
        </Alert>
        <h3 className="card-title">
          <span style={{ textDecoration: "line-through", fontSize: "15px" }}>$ 49,99</span>{" "}
          <span style={{}}>$ 19,99</span>
        </h3>
        <Button color="primary" onClick={() => history.push("/pro")}>
          Get PRO!
        </Button>
      </CardBody>
      <CardFooter>
        <h5>Unlimited projects</h5>
      </CardFooter>
    </Card>
  );
};

export default withRouter(ProAdd);

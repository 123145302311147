import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import NewStory from "../UI/NewStory/NewStory";
import Story from "../UI/Story/Story";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Row, Col } from "reactstrap";
import Explainer from "../UI/Explainer/Explainer";
import { Link } from "react-router-dom";

class BrainDump extends Component {
  constructor(props) {
    super(props);
    this.grid = 8;
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#e9ecef",
  });

  onDragEnd = (result) => {
    const { project } = this.props;
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const newGroups = { ...project.json };
    let sourceGroup = newGroups[source.droppableId];

    if (source.droppableId === destination.droppableId) {
      sourceGroup = this.reorder([...sourceGroup], source.index, destination.index);
      newGroups[source.droppableId] = sourceGroup;
    } else {
      if (!newGroups[destination.droppableId]) {
        newGroups[destination.droppableId] = [];
      }

      let destGroup = newGroups[destination.droppableId];
      const result = this.move([...sourceGroup], [...destGroup], source, destination);

      newGroups[source.droppableId] = result[source.droppableId];
      newGroups[destination.droppableId] = result[destination.droppableId];
    }
    this.props.saveProject({ ...project, json: newGroups });
  };

  getColumn = (group, key) => {
    return (
      <div className="itemlist" key={key}>
        <Droppable droppableId={key}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={this.getListStyle(snapshot.isDraggingOver)}
            >
              {group.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="itemlist__item"
                      style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      {
                        <Story
                          story={item}
                          project={this.props.project}
                          participants={this.props.participants}
                          stories={this.props.stories}
                          saveProject={this.props.saveProject}
                          user={this.props.user}
                          saveStory={this.props.saveStory}
                        />
                      }
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  documentUpdateHandler = (event, editor) => {
    const data = editor.getData();
    //console.log({ event, editor, data });
    const json = {
      ...this.props.project.json,
      document: data,
    };

    this.props.saveProjectDelay({ ...this.props.project, json });
  };

  render() {
    const { project, saveProject, stories } = this.props;

    const backlog =
      project.json && project.json.backlog
        ? project.json.backlog.map((storyId) => stories.find((story) => story.id === storyId))
        : [];

    return (
      <div className="overview">
        <h2>Step 1: Brain-dump</h2>
        <Row>
          <Col lg="6">
            <h3>What this project is all about...</h3>
            <CKEditor
              editor={ClassicEditor}
              data={project.json.document}
              className="main-editor"
              config={{
                ckfinder: {
                  // Upload the images to the server using the CKFinder QuickUpload command.
                  uploadUrl: `${window.ckFinderURL}/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json`,
                },
              }}
              /*
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}*/
              onChange={this.documentUpdateHandler} /*
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}*/
            />
            <br />
            <Explainer>
              <p>
                <strong>It's time to do some brain-dumping!</strong>
              </p>
              <p>
                Use this space in the very initial part of your project. Layout all your idea's in the "What this
                project is all about..." area. What does the endgoal look like? What obstacles are you going to be
                facing along the way?
              </p>
              <p>
                Then... lets add some tasks: Use the "add new task" field to add as much seperate things you can think
                of that need to be done in order to finish this project. Don't worry about prioritizing or anything like
                that, that's further down the line. For now just add tasks, and try to be as thorough as possible!
              </p>
              <p>Done? Then lets categorize your tasks in step 2!</p>
              <Link className="btn btn-primary" to={`/project/${project.id}/categorize`}>
                {"➜ Step 2: Categorize"}
              </Link>
            </Explainer>
          </Col>
          <Col lg="6">
            <DragDropContext onDragEnd={this.onDragEnd}>
              <h3>Let's add some tasks</h3>
              {this.getColumn(backlog, "backlog")}
              <NewStory onSubmit={saveProject} project={project} stories={stories} />
            </DragDropContext>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BrainDump;

import React, { useEffect, useState } from "react";
import zxcvbn from "zxcvbn";
import { toast } from "react-toastify";
import emailIsValid from "../../lib/ValidateEmail";
import { Button, Form, FormGroup, Label, Input, Spinner, Alert } from "reactstrap";
import { auth, signInWithGoogle, signInWithFacebook } from "../../firebase";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";

const Register = (props) => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [busy, setBusy] = useState(false);

  const source = props.match.params.source ? props.match.params.source : "regular";

  useEffect(() => {
    if (source !== "regular") {
      window.localStorage.setItem("source", source);
    }
  }, [source]);

  const signupHandler = async (event) => {
    event.preventDefault();

    if (!busy) {
      if (!firstName) {
        toast.warning("Enter your first name");
      } else if (!emailIsValid(email)) {
        toast.warning("Make sure you enter a valid email address");
      } else if (zxcvbn(password).score < 2) {
        toast.warning("Your password is to weak. Use lower- and upper case, numbers and special characters.");
      } else {
        setBusy(true);
        window.firstName = firstName;
        try {
          await auth.createUserWithEmailAndPassword(email, password);
          props.login();
        } catch (error) {
          console.log(error);
          setBusy(false);
          toast.warning(error?.message || "Error: couldn't sign up! Please try again!");
        }
      }
    }
  };

  return (
    <div className="register">
      <h2>Sign up for free!</h2>
      {source !== "regular" && <Alert color="info">2 free projects: {source}</Alert>}
      <GoogleLoginButton onClick={() => signInWithGoogle()}>Signup with Google</GoogleLoginButton>
      <FacebookLoginButton onClick={() => signInWithFacebook()}>Signup with Facebook</FacebookLoginButton>
      <Form onSubmit={signupHandler}>
        <br />
        <p>
          <strong>Or signup with username and password</strong>
        </p>
        <FormGroup>
          <Label for="firstName">First-name</Label>
          <Input
            type="text"
            name="firstName"
            id="firstName"
            placeholder="First name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="E-mail address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password">Password</Label>
          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Pick a password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroup>
        {busy ? <Spinner color="primary" /> : <Button color="primary">Sign up!</Button>}
      </Form>
    </div>
  );
};

export default Register;

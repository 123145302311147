import React from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./index.scss";
import App from "./App";
import UserProvider from "./context/UserProvider";

toast.configure({
  autoClose: 5000,
});

ReactDOM.render(
  <BrowserRouter>
    <UserProvider>
      <App />
    </UserProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

//<React.StrictMode>

import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const BreadCrumbs = ({ crumbs }) => {
  return (
    <Breadcrumb>
      {crumbs.map((crumb) => {
        return (
          <BreadcrumbItem key={crumb.title} active={crumb.active ? true : false}>
            {crumb.link ? <Link to={crumb.link}>{crumb.title}</Link> : crumb.title}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadCrumbs;

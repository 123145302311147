import React, { Component, useState } from "react";
import { CirclePicker } from "react-color";
import tinycolor2 from "tinycolor2";
import { Form, FormGroup, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { toast } from "react-toastify";
import { IoMdMore } from "react-icons/io";

class ChipEdit extends Component {
  node = React.createRef();
  state = {
    activeChip: null,
    newChip: "",
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.node.current.contains(e.target)) {
      this.setState({ activeChip: null });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { chips, project, saveProject } = this.props;
    const { newChip } = this.state;

    if (chips.find((chip) => chip.toLowerCase() === newChip.toLowerCase())) {
      toast.warning("You cant have duplicate tags!");
      return;
    }

    const json = { ...project.json };
    if (!json.chips) {
      json.chips = [];
    }
    json.chips.push(newChip);
    this.setState({ newChip: "" });
    saveProject({ ...project, json });
  };

  setActiveChip = (activeChip) => {
    this.setState({ activeChip });
  };

  render() {
    const { chips, project, saveProject } = this.props;
    const { activeChip, newChip } = this.state;

    return (
      <>
        <div className="greybox" style={{ marginBottom: 10 }} ref={this.node}>
          {chips.map((chip) => (
            <Chip
              key={chip}
              chip={chip}
              project={project}
              saveProject={saveProject}
              active={activeChip && activeChip === chip}
              setActiveChip={this.setActiveChip}
            />
          ))}
        </div>
        <Form onSubmit={this.submitHandler} className="new-note__form">
          <FormGroup>
            <Input
              type="text"
              placeholder="New project tag"
              name="newChip"
              id="newChip"
              value={newChip}
              onChange={(e) => this.setState({ newChip: e.target.value })}
            />
          </FormGroup>

          <Button color="primary" disabled={!newChip}>
            Add tag
          </Button>
        </Form>
      </>
    );
  }
}

const Chip = ({ chip, project, saveProject, active, setActiveChip }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const json = { ...project.json };
  const chipColor = json && json.chipColors ? json.chipColors.find((cc) => cc.chip === chip) : null;
  const color = chipColor ? chipColor.color : "#D9E3F0";

  const handleChangeComplete = (color) => {
    const colorSpecs = tinycolor2(color.hex);

    if (chipColor) {
      chipColor.color = color.hex;
      chipColor.isLight = colorSpecs.isLight();
    } else {
      if (!json.chipColors) {
        json.chipColors = [];
      }
      json.chipColors.push({ chip, color: color.hex, isLight: colorSpecs.isLight() });
    }
    setActiveChip(null);
    saveProject({ ...project, json });
  };

  const deleteHandler = () => {
    const chipIndex = json.chips.indexOf(chip);
    if (chipIndex > -1) {
      json.chips.splice(chipIndex, 1);
      saveProject({ ...project, json });
    }
  };

  return (
    <div className="chip-matthews">
      <p>{chip}</p>
      <div className="chip-matthews__right">
        <div className="chip-matthews__color" style={{ backgroundColor: color }} onClick={() => setActiveChip(chip)} />
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className="settings-button" color="link">
            <IoMdMore />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={deleteHandler}>Delete tag</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {active && (
          <div className="chip-matthews__picker">
            <CirclePicker color={color} onChangeComplete={handleChangeComplete} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChipEdit;

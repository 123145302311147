import React from "react";
import { Card, CardBody, CardImg, CardLink, CardText } from "reactstrap";
import Loader from "../UI/Loader/Loader";
import "./Feed.scss";

const Feed = ({ articles }) => {
  if (!articles) {
    return (
      <div className="owl-feed">
        <Loader />
      </div>
    );
  }

  return (
    <div className="owl-feed">
      {articles.map((article) => (
        <Card key={article.link} className="box-shadow">
          <a href={article.link}>
            <CardImg top width="100%" src={article.image} alt={article.title} />
          </a>
          <CardBody>
            <h5 className="card-title">{article.title}</h5>
            <CardText dangerouslySetInnerHTML={{ __html: `${article.description.substr(0, 200)}...` }} />
            <CardLink href={article.link}>Read more</CardLink>
          </CardBody>
        </Card>
      ))}
    </div>
  );
};

export default Feed;

import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Alert,
  Spinner,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { initialProject } from "../../lib/Variables";
import { confirmAlert } from "react-confirm-alert";
import { firestore } from "../../firebase";

const NewProject = ({ history, user, projects, membership, updateProjectBoard }) => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [busy, setBusy] = useState(false);

  const [projectName, setProjectName] = useState("");
  const toggle = () => {
    setModal(!modal);
  };

  const saveNewProject = () => {
    if (busy) {
      return;
    }

    if (projectName === "") {
      setError("Please enter a projectname");
    } else {
      setError("");
      setBusy(true);

      firestore
        .collection("projects")
        .add({
          name: projectName,
          json: { ...initialProject, projectName },
          user: user.id,
          active: 1,
          participantIds: [user.id],
          participants: [
            {
              userId: user.id,
              administrator: 1,
              displayName: user.displayName,
              avatar: user.avatar,
              email: user.email,
              inviteEmail: user.email,
            },
          ],
        })
        .then((result) => {
          toast.success("Your project is ready to go!");
          setProjectName("");
          setModal(false);
          setBusy(false);
          const newProjectBoard = {
            ...user.projectBoard,
            doing: [...(user.projectBoard?.doing || []), result.id],
          };
          updateProjectBoard(newProjectBoard);

          history.push(`/project/${result.id}/braindump`);
        });
    }
  };

  const proPopUp = () => {
    confirmAlert({
      title: "Reached your maximum projects!",
      message: `With your current plan you can have a maximum of ${
        user?.maxProjects > 1 ? `${user?.maxProjects} projects` : "1 project"
      }. Go One Week Leap PRO to start a new project!`,
      buttons: [
        {
          label: "Go PRO",
          onClick: () => {
            history.push(`/pro`);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const keyPressHandler = (e) => {
    if (e.which === 13) {
      saveNewProject();
    }
  };

  return (
    <>
      <Button
        color="primary"
        onClick={projects.length >= parseInt(user?.maxProjects || 1) && membership !== "pro" ? proPopUp : toggle}
      >
        New Project
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="">
        <ModalHeader toggle={toggle}>Create new project</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="projectName">Give your new project a catchy name!</Label>
            <Input
              type="text"
              name="projectName"
              id="projectName"
              placeholder="Project name"
              value={projectName}
              onKeyPress={keyPressHandler}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </FormGroup>
          {error ? <Alert color="warning">{error}</Alert> : null}
        </ModalBody>
        <ModalFooter>
          {busy ? (
            <Spinner color="primary" />
          ) : (
            <>
              <Button color="primary" onClick={saveNewProject}>
                Create new project
              </Button>{" "}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withRouter(NewProject);

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: "AIzaSyAZOjcQiifPb-q40pk_thPPxQRyGVxwgN4",
  authDomain: "oneweekleap.firebaseapp.com",
  projectId: "oneweekleap",
  storageBucket: "oneweekleap.appspot.com",
  messagingSenderId: "748070328638",
  appId: "1:748070328638:web:af2ddb9a4ce5d360b9346f",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firebaseFunctions = firebase.functions();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

const googleProvider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth.signInWithPopup(googleProvider).catch((error) => {
    toast.warning(error?.message);
  });
};

const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const signInWithFacebook = () => {
  auth.signInWithPopup(facebookProvider).catch((error) => {
    toast.warning(error?.message);
  });
};

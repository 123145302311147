import React, { useState } from "react";
import "./RangeStep.scss";
import { FormGroup, CustomInput, Button, Input, Tooltip, Spinner } from "reactstrap";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { primaryColor } from "../../../lib/Variables";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const RangeStepInput = ({
  story,
  saveStory,
  stories,
  project,
  saveProject,
  stepLabel,
  min,
  max,
  breakPoint,
  defaultValue,
  smallerBigger,
}) => {
  const [busy, setBusy] = useState(false);
  const [value, setValue] = useState(story.json[stepLabel]);
  const [splitting, setSplitting] = useState(false);
  const [splitStories, setSplitStories] = useState([
    { ...story, json: { ...story.json, [stepLabel]: defaultValue } },
    { ...story, json: { ...story.json, [stepLabel]: defaultValue, name: "" } },
  ]);

  const [lessTooltipOpen, setLessTooltipOpen] = useState(false);
  const lessTooltipToggle = () => setLessTooltipOpen((prevState) => !prevState);
  const [moreTooltipOpen, setMoreTooltipOpen] = useState(false);
  const moreTooltipToggle = () => setMoreTooltipOpen((prevState) => !prevState);

  const setRangeStep = (e) => {
    setValue(e.target.value);
  };

  const saveRangeStep = () => {
    const json = {
      ...story.json,
      [stepLabel]: value || defaultValue,
    };
    saveStory({ ...story, json });
    if (!value) {
      setValue(defaultValue);
    }
  };

  const updateSplitStories = (newStory, index) => {
    const newSplitStories = [...splitStories];
    newSplitStories[index] = newStory;
    setSplitStories(newSplitStories);
  };

  const oneLessHandler = () => {
    const newSplitStories = [...splitStories];
    newSplitStories.pop();
    setSplitStories(newSplitStories);
  };

  const oneMoreHandler = () => {
    const newSplitStories = [...splitStories];
    newSplitStories.push({
      ...story,
      json: { ...story.json, [stepLabel]: defaultValue, name: "" },
    });
    setSplitStories(newSplitStories);
  };

  const performSplit = () => {
    if (splitStories.find((s) => !s.json.name)) {
      toast.warning("Every split-task needs its own description");
      return;
    }
    setBusy(true);

    const json = { ...project.json };
    const startIndex = json.backlog.findIndex((s) => parseInt(s) === parseInt(splitStories[0].id));

    setValue(defaultValue);

    const newStories = [...stories];
    newStories[newStories.findIndex((s) => s.id === splitStories[0].id)] = splitStories[0];
    saveStory(splitStories[0]);
    addSplitStoryHandler(splitStories[1], 1, startIndex, newStories, splitStories, project);
  };

  const addSplitStoryHandler = (story, index, startIndex, stories, splitStories, project) => {
    const newStory = { ...story, id: uuidv4() };
    const newStories = [...stories];
    const json = { ...project.json };

    newStories.push(newStory);
    json.backlog.splice(startIndex + index, 0, newStory.id);

    if (index < splitStories.length - 1) {
      addSplitStoryHandler(splitStories[index + 1], index + 1, startIndex, newStories, splitStories, {
        ...project,
        json,
      });
    } else {
      saveProject({ ...project, json }, newStories);
      setSplitting(false);
      setBusy(false);
    }
  };

  let valueLabel = value;
  if (smallerBigger && parseInt(value) === min) {
    valueLabel = `< ${parseInt(value) + 1}`;
  } else if (smallerBigger && parseInt(value) === max) {
    valueLabel = `> ${parseInt(value) - 1}`;
  }

  return (
    <div className={"range-step-bar" + (value ? " range-step-bar--set" : "")}>
      <div className="story__body">
        <p>{story.json ? story.json.name : "..."}</p>
        <div className="range-step-bar__right">
          {value > breakPoint && !splitting && (
            <Button color="primary" size="sm" onClick={() => setSplitting(true)}>
              Split
            </Button>
          )}
          <span>{value ? `${valueLabel} ${stepLabel !== "hours" ? `/ ${max}` : "h"}` : `Set ${stepLabel}:`}</span>
          <div className="range-step-bar__input">
            <FormGroup>
              <CustomInput
                type="range"
                min={min}
                disabled={busy}
                max={max}
                step={1}
                onChange={setRangeStep}
                onMouseUp={saveRangeStep}
                value={value || defaultValue}
                name="range-step"
                id={`range-step-${story.id}`}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      {splitting && (
        <div className="range-step-bar__splitting">
          {splitStories.map((splitStory, index) => (
            <NewRangeStepStory
              key={index}
              story={splitStory}
              busy={busy}
              index={index}
              updateSplitStories={updateSplitStories}
            />
          ))}
          <div className="range-step-bar__splitting-footer">
            <div className="range-step-bar__splitting-icons">
              <div
                className="range-step-bar__splitting-button"
                style={{
                  display: splitStories.length < 3 || busy ? "none" : "block",
                }}
                id={`range-step-less-${story.id}`}
                onClick={oneLessHandler}
              >
                <FaMinusCircle size={25} color={primaryColor} />
              </div>
              <Tooltip isOpen={lessTooltipOpen} target={`range-step-less-${story.id}`} toggle={lessTooltipToggle}>
                Split into one less new story
              </Tooltip>

              <div
                className="range-step-bar__splitting-button"
                onClick={oneMoreHandler}
                style={{ display: busy ? "none" : "block" }}
                id={`range-step-more-${story.id}`}
              >
                <FaPlusCircle size={25} color={primaryColor} />
              </div>
              <Tooltip isOpen={moreTooltipOpen} target={`range-step-more-${story.id}`} toggle={moreTooltipToggle}>
                Split into one more new story
              </Tooltip>
            </div>
            {busy ? (
              <Spinner color="primary" />
            ) : (
              <div className="range-step-bar__splitting-icons">
                <Button onClick={() => setSplitting(false)}>Cancel split</Button>
                <Button onClick={performSplit}>Lets do this!</Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const NewRangeStepStory = ({ story, index, updateSplitStories, busy }) => {
  const updateNameHandler = (e) => {
    const json = { ...story.json, name: e.target.value };
    updateSplitStories({ ...story, json }, index);
  };
  return (
    <div className="range-step-bar__newstory">
      <FormGroup>
        <Input
          type="text"
          name={`range-step-new-story-${story.id}-${index}`}
          id={`range-step-new-story-${story.id}-${index}`}
          disabled={busy}
          value={story.json.name}
          onChange={updateNameHandler}
        />
      </FormGroup>
    </div>
  );
};

export default RangeStepInput;

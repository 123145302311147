import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Table,
} from "reactstrap";
import AppBody from "../UI/AppBody/AppBody";
import Avatar from "../UI/Avatar/Avatar";
import "./Profile.scss";
import { firestore, storage, firebaseFunctions, auth } from "../../firebase";
import { useUser } from "../../context/UserProvider";
import { Link } from "react-router-dom";

const Profile = ({ user, membership }) => {
  const [busy, setBusy] = useState(false);
  const [firebaseLoading, setFirebaseLoading] = useState(false);
  const [subscription, setSubscription] = useState();
  const updateAvatar = (formData) => {
    setBusy(true);
    const storageRef = storage.ref();

    // Create a reference to 'mountains.jpg'
    const imgRef = storageRef.child(`avatars/${user.id}.jpg`);

    imgRef
      .putString(formData, "base64")
      .then((snapshot) => {
        imgRef
          .getDownloadURL()
          .then((url) => {
            firestore
              .doc(`users/${user.id}`)
              .set({ avatar: url }, { merge: true });
            toast.success("Profile picture saved!");
            setBusy(false);
          })
          .catch((error) => {
            console.log(error);
            setBusy(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setBusy(false);
      });
  };

  useEffect(() => {
    const unsubscribeProjects = firestore
      .collection(`users/${user?.id}/subscriptions`)
      .where("status", "==", "active")
      .where("ended_at", "==", null)
      .onSnapshot((snapshot) => {
        const subscriptions = [];
        snapshot.forEach((doc) => {
          const subscription = doc.data();
          subscription.id = doc.id;
          subscriptions.push(subscription);
        });
        setSubscription(subscriptions?.[0] || null);
      });

    return () => unsubscribeProjects();
  }, [user?.id]);

  const userDisplayName = user?.displayName;
  const [firstName, setFirstName] = useState("");
  const [formBusy, setFormBusy] = useState(false);

  useEffect(() => {
    if (firstName === "" && userDisplayName) {
      setFirstName(userDisplayName);
    }
  }, [userDisplayName, firstName]);

  const fbuser = useUser();
  let isLoggedInWithPassword = false;
  fbuser.providerData.forEach((providerData) => {
    if (providerData.providerId === "password") {
      isLoggedInWithPassword = true;
    }
  });

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formBusy) {
      if (!firstName) {
        toast.warning("Enter your first name");
      } else {
        setFormBusy(true);

        firestore
          .doc(`users/${user.id}`)
          .set({ displayName: firstName }, { merge: true })
          .then(() => {
            toast.success("Personals saved!");
            setFormBusy(false);
          });
      }
    }
  };

  let currentPlan =
    user?.source && user?.source === "regular" ? "Free" : user?.source;
  if (membership === "pro") {
    currentPlan = "One Week Leap PRO";
  }

  const goToFirebase = async () => {
    setFirebaseLoading(true);
    const functionRef = firebaseFunctions.httpsCallable(
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      returnUrl: window.location.origin,
      locale: "auto", // Optional, defaults to "auto"
    });
    setFirebaseLoading(false);
    window.location.assign(data.url);
    //      configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
  };

  const resetPassword = () => {
    auth.sendPasswordResetEmail(user.email).then(() => {
      toast.success("We've sent the password reset link to your email!");
    });
  };

  return (
    <AppBody>
      <div className="profile">
        <h1>My Profile</h1>
        <Avatar avatar={user?.avatar} updateAvatar={updateAvatar} busy={busy} />
        <h3>Personals</h3>
        <Form onSubmit={submitHandler}>
          <FormGroup>
            <Label for="firstName">Your name</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>
          {formBusy ? (
            <Spinner color="primary" />
          ) : (
            <Button color="primary">Save my personals</Button>
          )}
        </Form>
        <br />
        {isLoggedInWithPassword && (
          <Button color="primary" onClick={resetPassword}>
            Reset password
          </Button>
        )}
        <br />
        <br />
        <Card
          className="box-shadow"
          style={{ textAlign: "center", margin: "auto" }}
        >
          <CardHeader>
            <h4>Current plan</h4>
          </CardHeader>
          <CardBody>
            <h3 className="card-title" style={{ textTransform: "capitalize " }}>
              {currentPlan}
            </h3>

            {membership === "pro" ? (
              <h5>Unlimited projects</h5>
            ) : (
              <h5>
                {user?.maxProjects} project
                {Number(user?.maxProjects) > 1 ? "s" : ""}
              </h5>
            )}
            {membership === "pro" && (
              <>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>Starting date</td>

                      <td>
                        {subscription?.current_period_start &&
                          subscription?.current_period_start
                            .toDate()
                            .toLocaleDateString(undefined, {
                              weekday: "short",
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })}
                      </td>
                    </tr>
                    <tr>
                      <td>Renewal date*</td>
                      <td>
                        {subscription?.current_period_end &&
                          subscription?.current_period_end
                            .toDate()
                            .toLocaleDateString(undefined, {
                              weekday: "short",
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <p>
                  * To cancel renewal or terminate your subscription; click the
                  button below
                </p>
                {firebaseLoading ? (
                  <Spinner color="primary" />
                ) : (
                  <Button color="primary" onClick={goToFirebase}>
                    Edit subscription
                  </Button>
                )}
              </>
            )}
            {membership !== "pro" && (
              <Link to="/pro">Upgrade your account</Link>
            )}
          </CardBody>
        </Card>
      </div>
    </AppBody>
  );
};

export default Profile;

import React from "react";
import "./Header.scss";
import {
  Button,
  Navbar,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import AvatarPic from "../UI/AvatarPic/AvatarPic";
import { BsChatFill } from "react-icons/bs";
import OWLIconWhite from "../../img/owl-icon-white.svg";
import { auth, firestore } from "../../firebase";

const Header = ({
  loggedIn,
  user,
  history,
  loading,
  projects,
  notifications,
  projectBoard,
}) => {
  const notificationClick = (notification) => {
    console.log("notification", notification);

    if (parseInt(notification.innerlink) === 1) {
      history.push(notification.link);
    } else {
      window.location.href = notification.link;
    }
    firestore.doc(`notifications/${notification.id}`).set(
      {
        unread: 0,
        readtime: new Date(),
      },
      { merge: true }
    );
  };

  return (
    <Navbar color="primary" dark expand="md" className="main-nav-bar">
      <div className="header__icon" onClick={() => history.push("/")}>
        <img alt="owl-icon" src={OWLIconWhite} />
      </div>
      <Nav className="mr-auto" navbar>
        {loggedIn &&
          projects &&
          projects.length > 0 &&
          projectBoard?.doing &&
          projectBoard.doing.length > 0 && (
            <UncontrolledDropdown nav>
              <DropdownToggle nav caret>
                Projects
              </DropdownToggle>
              <DropdownMenu right>
                {projectBoard.doing
                  .filter((pId) => !!projects.find((p) => p.id === pId))
                  .map((pId) => projects.find((p) => p.id === pId))
                  .map((p) => (
                    <DropdownItem
                      key={p.id}
                      onClick={() => history.push(`/project/${p.id}`)}
                    >
                      {p.name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
      </Nav>
      {!loading && (
        <Nav navbar className="align-items-center header__right">
          {loggedIn ? (
            <>
              {notifications && notifications.length > 0 && (
                <UncontrolledDropdown nav>
                  <DropdownToggle nav className="header__notification-button">
                    <BsChatFill />
                    <span>{notifications.length}</span>
                  </DropdownToggle>
                  <DropdownMenu right className="header__notifications">
                    {notifications.map((notification) => (
                      <DropdownItem
                        key={notification.id}
                        onClick={() => notificationClick(notification)}
                        className={
                          "header__notification" +
                          (parseInt(notification.unread) === 1
                            ? " header__notification--unread"
                            : "")
                        }
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: notification.message,
                          }}
                        />
                        <span className="header__notification--subtitle">
                          {notification.subMessage}
                        </span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              <UncontrolledDropdown nav>
                <DropdownToggle nav className="header__avatar">
                  <AvatarPic
                    src={user?.avatar}
                    style={{ width: "35px", height: "35px" }}
                  />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => history.push("/profile")}>
                    Profile
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => auth.signOut()}>
                    Log-out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ) : (
            <>
              <NavItem>
                <Link className="nav-link" to="/register">
                  Create Free account!
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link nav-link--login" to="/login">
                  <Button outline color="light" type="submit">
                    Login
                  </Button>
                </Link>
              </NavItem>
            </>
          )}
        </Nav>
      )}
    </Navbar>
  );
};

export default withRouter(Header);
/*
 <NavItem>
            <NavLink href="/components/">Components</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Options
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>Option 1</DropdownItem>
              <DropdownItem>Option 2</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Reset</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
*/

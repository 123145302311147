import React, { Component, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import NewStory from "../UI/NewStory/NewStory";
import Story from "../UI/Story/Story";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Explainer from "../UI/Explainer/Explainer";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { IoMdMore } from "react-icons/io";
import { CirclePicker } from "react-color";
import tinycolor2 from "tinycolor2";

class Categorize extends Component {
  constructor(props) {
    super(props);
    this.grid = 8;
    this.state = {
      newCat: "",
      allStories: [],
      activeCat: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    const allStories =
      props.project.json && props.project.json.backlog
        ? props.project.json.backlog.map((storyId) => props.stories.find((story) => story.id === storyId))
        : [];

    const newState = {
      allStories,
    };
    return newState;
  }

  newCatHandler = (e) => {
    e.preventDefault();
    const { newCat } = this.state;
    const cats = this.props.project?.json?.categories ? this.props.project?.json?.categories : [];
    if (cats.find((cat) => cat.name.toLowerCase() === newCat.toLowerCase())) {
      toast.warning("You cant have duplicate categories!");
      return;
    }
    cats.push({ name: newCat, color: "#D9E3F0", isLight: true });
    const projectJson = {
      ...this.props.project.json,
      categories: cats,
    };
    this.props.saveProject({ ...this.props.project, json: projectJson });
    this.setState({ newCat: "" });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#e9ecef",
  });

  onDragEnd = (result) => {
    const { stories } = this.props;
    const { source, destination, draggableId } = result;
    const story = {
      ...stories.find((story) => story.id === draggableId),
    };

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      return;
    }
    if (destination.droppableId === "backlog") {
      delete story.json.category;
    } else {
      story.json.category = destination.droppableId;
    }
    const newAllStories = [...this.state.allStories];
    newAllStories[newAllStories.findIndex((s) => s.id === draggableId)] = story;
    this.setState({ allStories: newAllStories });
    this.props.saveStory(story);
  };

  getColumn = (group, key, color) => {
    const { activeCat } = this.state;
    const { saveProject, project, stories } = this.props;

    return (
      <div className="category" key={key}>
        {key !== "backlog" && (
          <ColumnTop
            catName={key}
            color={color}
            activeCat={activeCat}
            setActiveCat={(v) => this.setState({ activeCat: v })}
            saveProject={saveProject}
            project={project}
            stories={stories}
          />
        )}

        <div className="itemlist">
          <Droppable droppableId={key}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
              >
                {group.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="itemlist__item"
                        style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        {
                          <Story
                            story={item}
                            project={this.props.project}
                            participants={this.props.participants}
                            stories={this.props.stories}
                            saveProject={this.props.saveProject}
                            user={this.props.user}
                            saveStory={this.props.saveStory}
                          />
                        }
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <br />
      </div>
    );
  };

  render() {
    const { project, saveProject, stories } = this.props;
    const { allStories, newCat } = this.state;

    return (
      <div className="moscow">
        <h2>Step 2: Categorize</h2>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Row>
            <Col lg="6">
              <h3>Backlog</h3>
              {this.getColumn(
                allStories.filter((s) => !s.json?.category),
                "backlog"
              )}
              <NewStory onSubmit={saveProject} project={project} stories={stories} />
            </Col>
            <Col lg="6">
              <h3>Categories</h3>
              <Form onSubmit={this.newCatHandler} className="new-note__form">
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="New category name"
                    name="newChip"
                    id="newChip"
                    value={newCat}
                    onChange={(e) => this.setState({ newCat: e.target.value })}
                  />
                </FormGroup>
                <Button color="primary" disabled={!newCat}>
                  Add category
                </Button>
              </Form>
              <br />
              {project?.json?.categories?.map((cat) =>
                this.getColumn(
                  allStories.filter((s) => s.json.category === cat.name),
                  cat.name,
                  cat.color
                )
              )}
              <br />
              <Explainer>
                <p>
                  <strong>Lets categorize!</strong>
                </p>
                <p>Now that you've brainstormed some tasks, let's categorize them.</p>
                <p>
                  Add some main categories and give them a distinctive color. Then drag the tasks to their category.
                </p>
                <p>
                  This step is just for main - toplevel categories. You can always add multiple labels to tasks later.
                </p>
                <p>Done? Then lets sort your tasks according to the MoScoW standard in step 3!</p>
                <Link className="btn btn-primary" to={`/project/${project.id}/moscow`}>
                  {"➜ Step 3: MoSCoW"}
                </Link>
              </Explainer>
            </Col>
          </Row>
        </DragDropContext>
      </div>
    );
  }
}

const ColumnTop = ({ catName, color, activeCat, setActiveCat, saveProject, project, stories }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const json = { ...project.json };
  const [newName, setNewName] = useState(catName);
  const [changingTitle, setChangingTitle] = useState(false);

  const handleChangeComplete = (newColor) => {
    const colorSpecs = tinycolor2(newColor.hex);

    const index = json.categories.findIndex((c) => c.name === catName);
    json.categories[index].color = newColor.hex;
    json.categories[index].isLight = colorSpecs.isLight();

    setActiveCat("");
    saveProject({ ...project, json });
  };

  const deleteHandler = () => {
    const index = json.categories.findIndex((c) => c.name === catName);
    if (index > -1) {
      json.categories.splice(index, 1);
      const newStories = stories.map((s) => {
        const newStory = { ...s };
        if (s.json?.category === catName) {
          delete s.json.category;
        }
        return newStory;
      });
      saveProject({ ...project, json }, newStories);
    }
  };

  const changeNameHandler = (e) => {
    e.preventDefault();
    if (!newName) {
      return;
    }
    if (newName === catName) {
      setChangingTitle(false);
      return;
    }
    if (project.json.categories.some((c) => c.name.toLowerCase() === newName.toLowerCase())) {
      toast.warning("You cant have duplicate categories!");
      return;
    }
    const index = json.categories.findIndex((c) => c.name === catName);
    json.categories[index].name = newName;
    const newStories = stories.map((s) => {
      const newStory = { ...s };
      if (s.json?.category === catName) {
        s.json.category = newName;
      }
      return newStory;
    });
    saveProject({ ...project, json }, newStories);
    setChangingTitle(false);
  };

  return (
    <div className="categorize-header">
      {changingTitle ? (
        <Form onSubmit={changeNameHandler} className="new-note__form">
          <FormGroup>
            <Input
              type="text"
              name="title"
              bsSize="lg"
              id="title"
              placeholder="Category name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </FormGroup>
          <Button color="primary" disabled={!newName}>
            Save
          </Button>
        </Form>
      ) : (
        <h4>{catName}</h4>
      )}
      <div className="categorize-header__options">
        <div
          className="chip-matthews__color"
          style={{ backgroundColor: color }}
          onClick={() => setActiveCat(catName)}
        />
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className="settings-button" color="link">
            <IoMdMore />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setChangingTitle(true)}>Change name</DropdownItem>
            <DropdownItem onClick={deleteHandler}>Delete category</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {activeCat === catName && (
          <div className="chip-matthews__picker">
            <CirclePicker color={color} onChangeComplete={handleChangeComplete} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Categorize;

import React, { useEffect, useState } from "react";
import "./App.scss";
import { useUser } from "./context/UserProvider";
import LoggedOut from "./LoggedOut";
import LoggedIn from "./LoggedIn";
import ConfirmEmail from "./ConfirmEmail";

const App = () => {
  const [articles, setArticles] = useState([]);
  const user = useUser();

  useEffect(() => {
    fetch("https://oneweekleap.com/feed")
      .then((response) => response.text())
      .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
      .then((data) => {
        const articles = [];
        const items = data.querySelectorAll("item");
        items.forEach((el) => {
          articles.push({
            title: el.querySelector("title").innerHTML,
            link: el.querySelector("link").innerHTML,
            pubDate: el.querySelector("pubDate").innerHTML,
            description: el.querySelector("description").childNodes[0].nodeValue,
            image: el.querySelector("media\\:content, content").getAttribute("url"),
          });
          setArticles(articles);
        });
      });
  }, []);

  if (user?.emailVerified) {
    return <LoggedIn articles={articles} />;
  }
  if (!!user) {
    return <ConfirmEmail articles={articles} user={user} />;
  }

  return <LoggedOut articles={articles} />;
};

export default App;

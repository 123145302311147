import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Div100vh from "react-div-100vh";
import Header from "./components/Header/Header";
import AppBody from "./components/UI/AppBody/AppBody";
import background from "./img/owl-background.svg";
import { Button } from "reactstrap";
import { toast } from "react-toastify";

const ConfirmEmail = (props) => {
  const user = props.user;

  const onFocus = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);

    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, []);

  return (
    <Div100vh>
      <div className="app">
        <Header loggedIn={true} />
        <div className="app-body app-body--reverse">
          <AppBody>
            <img src={background} className="owl-background" alt="background" />
            <div className="logincard">
              <div className="logincard__content">
                <div className="logincard__body">
                  <div className="login">
                    <h2>Confirm e-mail address</h2>
                    <p>We have sent a confirmation email to your email address: {user.email}</p>
                    <p>Please confirm your e-mail address by clicking the link in the email we have sent you.</p>
                    <p>
                      If you didn't receive an email (please also check your spam folder) then you can click the button
                      below to resend the confirmation email.
                    </p>
                    <Button
                      color="primary"
                      onClick={() => {
                        user.sendEmailVerification();
                        toast.success("Email sent. Please check your inbox and spam folders");
                      }}
                    >
                      Resend confirmation email
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </AppBody>
        </div>
      </div>
    </Div100vh>
  );
};

export default withRouter(ConfirmEmail);

import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import "./App.scss";
import Div100vh from "react-div-100vh";
import Header from "./components/Header/Header";
import Projects from "./components/Projects/Projects";
import Project from "./components/Project/Project";
import Profile from "./components/Profile/Profile";
import Pro from "./components/Pro/Pro";
import { firestore, auth } from "./firebase";
import { UserContext } from "./context/UserProvider";

class LoggedIn extends Component {
  static contextType = UserContext;
  state = {
    user: null,
    projects: null,
    membership: null,
    notifications: null,
  };

  componentDidMount() {
    const user = this.context;
    const getCustomClaimRole = async () => {
      await auth.currentUser.getIdToken(true);
      const decodedToken = await auth.currentUser.getIdTokenResult();
      if (decodedToken.claims.stripeRole) {
        this.setState({ membership: decodedToken.claims.stripeRole });
      }
      return decodedToken.claims.stripeRole;
    };
    getCustomClaimRole();

    this.unsubscribeUser = firestore
      .doc(`users/${user.uid}`)
      .onSnapshot((doc) => {
        const fbuser = doc.data();
        this.setState({
          user: { ...fbuser, id: user.uid },
        });
      });

    this.unsubscribeProjects = firestore
      .collection("projects")
      .where("participantIds", "array-contains", user.uid)
      .where("active", "==", 1)
      .onSnapshot((snapshot) => {
        const projects = [];
        snapshot.forEach((doc) => {
          const project = doc.data();
          project.id = doc.id;
          projects.push(project);
        });
        this.setState({
          projects,
        });
      });

    this.unsubscribeNotifications = firestore
      .collection("notifications")
      .where("person", "==", user.uid)
      .where("unread", "==", 1)
      .onSnapshot((snapshot) => {
        const notifications = [];
        snapshot.forEach((doc) => {
          const notification = doc.data();
          notification.id = doc.id;
          notifications.push(notification);
        });
        this.setState({
          notifications,
        });
      });
    // to get membership from php (connect with firebase admin)
  }

  componentWillUnmount() {
    this.unsubscribeUser();
    this.unsubscribeProjects();
    this.unsubscribeNotifications();
  }

  updateUser = (user) => {
    this.setState({ user });
    firestore.doc(`users/${this.state.user.id}`).set(user, { merge: true });
  };

  updateMembership = (membership) => {
    this.setState({ membership });
  };

  updateProjectBoard = (projectBoard) => {
    this.updateUser({ ...this.state.user, projectBoard });
  };

  render() {
    const { user, projects, notifications, membership } = this.state;
    const { articles } = this.props;

    return (
      <Div100vh>
        <div className="app">
          <Header
            loggedIn={true}
            user={user}
            projects={projects}
            logOut={this.signOutHandler}
            notifications={notifications}
            projectBoard={user?.projectBoard}
          />
          <div className="app-body">
            <Switch>
              <Route
                path="/project/:projectId/:step/:stepSubId"
                render={(props) => <Project {...props} user={user} />}
              />
              <Route
                path="/project/:projectId/:step"
                render={(props) => <Project {...props} user={user} />}
              />
              <Route
                path="/project/:projectId"
                render={(props) => <Project {...props} user={user} />}
              />
              <Route
                path="/profile"
                render={(props) => (
                  <Profile
                    {...props}
                    user={user}
                    updateUser={this.updateUser}
                    membership={membership}
                  />
                )}
              />
              <Route
                path="/pro/success"
                render={(props) => (
                  <Pro
                    {...props}
                    user={user}
                    proSuccess={true}
                    membership={membership}
                    updateMembership={this.updateMembership}
                    testing={this.props.testing}
                  />
                )}
              />
              <Route
                path="/pro"
                render={(props) => (
                  <Pro
                    {...props}
                    user={user}
                    membership={membership}
                    updateMembership={this.updateMembership}
                    testing={this.props.testing}
                  />
                )}
              />
              <Route
                path="/projects"
                render={(props) => (
                  <Projects
                    {...props}
                    articles={articles}
                    user={user}
                    projects={projects}
                    membership={membership}
                    projectBoard={user?.projectBoard}
                    updateProjectBoard={this.updateProjectBoard}
                  />
                )}
              />
              <Redirect from="/" to="/projects" />
            </Switch>
          </div>
        </div>
      </Div100vh>
    );
  }
}

export default withRouter(LoggedIn);

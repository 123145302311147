/* eslint func-names: 0, no-param-reassign: 0 */ // --> OFF
import React, { Component } from "react";
import AvatarEditor from "react-avatar-edit";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import AvatarPic from "../AvatarPic/AvatarPic";
import Loader from "../Loader/Loader";
import "./Avatar.scss";

class Avatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: null,
      src: null,
      editMode: false,
    };
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
  }

  onClose() {
    this.setState({ preview: null });
  }

  onCrop(preview) {
    this.setState({ preview });
  }

  handleSubmit = () => {
    this.setState({ editMode: false });
    this.props.updateAvatar(this.state.preview.split(",")[1]);
  };

  onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 1000000) {
      toast.warning("File is too big!");
      elem.target.value = "";
    }
  }

  render() {
    const { editMode, preview } = this.state;
    const { avatar, busy } = this.props;
    return (
      <div className="owl-avatar-edit">
        <div
          className="owl-avatar-edit__pic"
          onClick={() => this.setState({ editMode: true })}
        >
          <AvatarPic src={this.state.preview ? this.state.preview : avatar} />
        </div>
        <div
          className={`owl-avatar-editor${
            editMode ? "" : " owl-avatar-editor--hide"
          }`}
        >
          <AvatarEditor
            width={300}
            height={300}
            onCrop={this.onCrop}
            onClose={this.onClose}
            onBeforeFileLoad={this.onBeforeFileLoad}
            src={this.state.src}
            mimeTypes="image/*"
          />
          {busy ? (
            <Loader />
          ) : (
            <Button onClick={this.handleSubmit} disabled={!preview}>
              Save avatar!
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default Avatar;

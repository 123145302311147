import React, { Component } from "react";
import Select from "react-select";
import "./Chips.scss";

class Chips extends Component {
  state = {
    chipsSelectInputValue: "",
  };

  addChip = (selectedOption) => {
    const { project, saveProject, story } = this.props;
    const chips = this.props.chips.slice();
    const calcChips = project.json.chips ? project.json.chips : [];
    const projectChip = !!calcChips.find(
      (obj) => obj.toLowerCase() === selectedOption.value.toLowerCase()
    );

    const objIndex = chips.findIndex(
      (obj) => obj.toLowerCase() === selectedOption.value.toLowerCase()
    );
    if (objIndex === -1) {
      chips.push(selectedOption.value);
      if (projectChip) {
        this.props.setChips(chips);
      } else {
        const stories = [...project.stories];
        stories[stories.findIndex((s) => s.id === story.id)] = {
          ...story,
          json: {
            ...story.json,
            chips,
          },
        };
        const json = {
          ...project.json,
          chips: [...calcChips, selectedOption.value],
        };
        this.props.setChips(chips);
        saveProject({ ...project, json }, stories);
      }
    }
  };

  removeChip = (chip) => {
    const chips = this.props.chips.slice();
    const objIndex = chips.findIndex((obj) => obj === chip);
    if (objIndex > -1) {
      chips.splice(objIndex, 1);
      this.props.setChips(chips);
    }
  };

  chipsSelectInputHandler = (value) => {
    this.setState({ chipsSelectInputValue: value });
  };

  render() {
    const { allChips, chips, chipColors } = this.props;
    const { chipsSelectInputValue } = this.state;

    const filteredChips = allChips
      .filter((x) => !chips.includes(x))
      .filter((x, i, a) => a.indexOf(x) === i);
    let chipOptions = filteredChips.map((chip) => {
      return { value: chip, label: chip };
    });

    if (chipsSelectInputValue) {
      chipOptions.unshift({
        value: chipsSelectInputValue,
        label: chipsSelectInputValue,
      });
    }

    const chipElements = chips
      .filter((c) => allChips.includes(c))
      .map((chip) => {
        if (chip) {
          const chipColor = chipColors
            ? chipColors.find((cc) => cc.chip === chip)
            : null;
          return (
            <span
              key={chip}
              data-chip={chip}
              style={{
                backgroundColor: chipColor ? chipColor.color : "#6c757d",
                color: chipColor && chipColor.isLight ? "black" : "white",
              }}
              className="chips__chip badge"
              onClick={() => this.removeChip(chip)}
            >
              {chip}
            </span>
          );
        } else {
          return null;
        }
      });

    return (
      <div className="chips">
        <div className="chips__chips">{chipElements}</div>
        <Select
          placeholder="add label"
          className="chips__select"
          options={chipOptions}
          onChange={this.addChip}
          onInputChange={this.chipsSelectInputHandler}
          inputValue={chipsSelectInputValue}
        />
      </div>
    );
  }
}

export default Chips;

/*
 theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#BD3381",
            },
          })}
*/

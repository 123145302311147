import React, { useState, Component } from "react";
import NewProject from "./NewProject";
import Loader from "../UI/Loader/Loader";
import "./Projects.scss";
import { Link } from "react-router-dom";
import AppBody from "../UI/AppBody/AppBody";
import SideBar from "../UI/SideBar/SideBar";
import AvatarPic from "../UI/AvatarPic/AvatarPic";
import { Tooltip } from "reactstrap";
import { FaClock } from "react-icons/fa";
import Feed from "../Feed/Feed";
import ProAdd from "../ProAdd/ProAdd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const ProjectPerson = ({ person, preFix }) => {
  const [personOpen, setPersonOpen] = useState(false);
  const personToggle = () => setPersonOpen((prevState) => !prevState);
  return (
    <>
      <AvatarPic
        noShadow
        id={`${preFix}-person-${person.userId}`}
        src={person.avatar}
        style={{ width: "25px", height: "25px" }}
      />
      <Tooltip
        placement="top"
        isOpen={personOpen}
        target={`${preFix}-person-${person.userId}`}
        toggle={personToggle}
      >
        {`${person.displayName}`}
      </Tooltip>
    </>
  );
};

const ProjectElement = ({ project }) => {
  const [projectTimeIconOpen, setProjectTimeIconOpen] = useState(false);
  const projectTimeToggle = () =>
    setProjectTimeIconOpen((prevState) => !prevState);

  const lastSaveDate = new Date(project.lastSave).toLocaleDateString(
    undefined,
    {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }
  );
  return (
    <>
      <Link className={"story"} to={`/project/${project.id}`}>
        <div className="story__body">
          <p>{project.name}</p>
          <div className="story__icons">
            <div className="story__icon" id={`projectTime-${project.id}`}>
              <FaClock color={"#17a2b8"} />
              <Tooltip
                placement="left"
                isOpen={projectTimeIconOpen}
                target={`projectTime-${project.id}`}
                toggle={projectTimeToggle}
              >
                {`Last saved: ${lastSaveDate}`}
              </Tooltip>
            </div>
            {project.participants
              .filter((p) => !!p.userId)
              .map((person) => (
                <ProjectPerson
                  key={person.userId}
                  person={person}
                  preFix={`project-${project.id}`}
                />
              ))}
          </div>
        </div>
      </Link>
    </>
  );
};

class Projects extends Component {
  state = {
    todo: [],
    doing: [],
    done: [],
  };

  static getDerivedStateFromProps(props, state) {
    let todo = [];
    let doing = [];
    let done = [];

    if (props.projectBoard && props.projects) {
      todo = props.projectBoard.todo
        .filter((pId) => !!props.projects.find((p) => p.id === pId))
        .map((pId) => props.projects.find((p) => p.id === pId));
      todo = todo.concat(
        props.projects.filter(
          (p) =>
            props.projectBoard.todo
              .concat(props.projectBoard.doing)
              .concat(props.projectBoard.done)
              .findIndex((pId) => p.id === pId) === -1
        )
      );
      doing = props.projectBoard.doing
        .filter((pId) => !!props.projects.find((p) => p.id === pId))
        .map((pId) => props.projects.find((p) => p.id === pId));
      done = props.projectBoard.done
        .filter((pId) => !!props.projects.find((p) => p.id === pId))
        .map((pId) => props.projects.find((p) => p.id === pId));
    } else if (props.projects) {
      todo = props.projects.map((p) => p);
    }

    const newState = {
      todo,
      doing,
      done,
    };
    return newState;
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#e9ecef",
  });

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const newGroups = { ...this.state };
    let sourceGroup = newGroups[source.droppableId];

    if (source.droppableId === destination.droppableId) {
      sourceGroup = this.reorder(
        [...sourceGroup],
        source.index,
        destination.index
      );
      newGroups[source.droppableId] = sourceGroup;
    } else {
      if (!newGroups[destination.droppableId]) {
        newGroups[destination.droppableId] = [];
      }

      let destGroup = newGroups[destination.droppableId];
      const result = this.move(
        [...sourceGroup],
        [...destGroup],
        source,
        destination
      );

      newGroups[source.droppableId] = result[source.droppableId];
      newGroups[destination.droppableId] = result[destination.droppableId];
    }

    const projectBoard = {
      todo: newGroups.todo.map((p) => p.id),
      doing: newGroups.doing.map((p) => p.id),
      done: newGroups.done.map((p) => p.id),
    };
    this.props.updateProjectBoard(projectBoard);
  };

  getColumn = (group, key) => {
    return (
      <div className="itemlist" key={key}>
        <Droppable droppableId={key}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={this.getListStyle(snapshot.isDraggingOver)}
            >
              {group.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="itemlist__item"
                      style={this.getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {<ProjectElement project={item} />}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  render() {
    const { projects, user, membership, articles, updateProjectBoard } =
      this.props;
    const { todo, doing, done } = this.state;

    return (
      <>
        <SideBar title="Tips & Articles">
          {membership !== "pro" && <ProAdd />}
          <Feed articles={articles} />
        </SideBar>
        <AppBody>
          <div className="projects">
            <div className="projects__header">
              <h1>My Projects</h1>
              {projects && (
                <NewProject
                  user={user}
                  projects={projects}
                  membership={membership}
                  updateProjectBoard={updateProjectBoard}
                />
              )}
            </div>{" "}
            {projects ? (
              <div className="owl__columns">
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <div className="owl__column">
                    <h3>To do</h3>
                    {this.getColumn(todo, "todo")}
                  </div>
                  <div className="owl__column">
                    <h3>Doing</h3>
                    {this.getColumn(doing, "doing")}
                  </div>
                  <div className="owl__column">
                    <h3>Done</h3>
                    {this.getColumn(done, "done")}
                  </div>
                </DragDropContext>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </AppBody>
      </>
    );
  }
}

export default Projects;

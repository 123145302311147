import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FormGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import Chat from "../../Chat/Chat";
import generateUUID from "../../../lib/GenerateUUID";
import { FaSlackHash } from "react-icons/fa";

class ChatPage extends Component {
  constructor(props) {
    super(props);

    const chatRoom = props.chatRooms?.find(
      (chatRoom) => chatRoom.id === props.match.params.stepSubId
    );

    this.state = {
      loading: true,
      changingTitle: false,
      chatTitle: chatRoom.title || "",
    };
  }

  fieldUpdateHandler = (event) => {
    this.setState({ chatTitle: event.target.value });
    const chatRoom = this.props.chatRooms?.find(
      (chatRoom) => chatRoom.id === this.props.match.params.stepSubId
    );
    this.updateHandler({ ...chatRoom, [event.target.id]: event.target.value });
  };

  updateHandler = (chat, rightAway = false) => {
    if (!chat.title) {
      return;
    }
    const sameTitle = this.props.chatRooms.filter(
      (n) => n.title.toLowerCase() === chat.title.toLowerCase()
    );

    if (sameTitle.length > 0 && sameTitle[0].id !== chat.id) {
      toast.warning("There's already a chat with this title!");
      return;
    }

    clearTimeout(this.saveTimer);
    if (rightAway) {
      this.props.saveChat(chat);
    } else {
      this.saveTimer = setTimeout(() => {
        this.props.saveChat(chat);
      }, 200);
    }
  };

  deleteHandler = () => {
    confirmAlert({
      title: "Really delete chat?",
      message: "This cannot be undone",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            clearTimeout(this.saveTimer);
            const chatRoom = this.props.chatRooms?.find(
              (chatRoom) => chatRoom.id === this.props.match.params.stepSubId
            );

            const newChats = this.props.chatRooms.filter(
              (c) => c.id !== chatRoom.id
            );
            this.props.saveChats(newChats);

            setTimeout(() => {
              this.props.history.push(`/project/${this.props.project.id}`);
              toast.warning("Chat deleted");
            }, 200);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  saveMessageHandler = (message, messageId = 0, mentions = []) => {
    const { user, chatRooms, saveChat } = this.props;
    const chatRoom = chatRooms?.find(
      (chatRoom) => chatRoom.id === this.props.match.params.stepSubId
    );
    const chat = chatRoom.chat;
    const tempGuid = generateUUID();

    if (messageId > 0) {
      const index = chat.findIndex((m) => m.guid === messageId);
      if (index > -1) {
        chat[index].message = message;
      }
    } else {
      chat.push({
        message,
        user: user.id,
        name: `${user.displayName}`,
        date: String(new Date()),
        guid: tempGuid,
      });
    }
    saveChat({ ...chatRoom, chat });
  };

  render() {
    const { dropdownOpen, changingTitle, chatTitle } = this.state;
    const { project, participants, user, stories, saveChat, chatRooms } =
      this.props;

    const chat = chatRooms?.find(
      (chatRoom) => chatRoom.id === this.props.match.params.stepSubId
    );

    const userParticipant = participants?.find((p) => p.userId === user?.id);
    const isAdmin =
      userParticipant && userParticipant.administrator === 1 ? true : false;

    if (!chat) {
      return (
        <div>
          <h2>Error: Can't find requested channel</h2>
        </div>
      );
    }

    return (
      <div className="chatpage">
        <div className="notepage__header">
          <FaSlackHash />
          {changingTitle ? (
            <FormGroup>
              <Input
                type="text"
                name="title"
                bsSize="lg"
                id="title"
                placeholder="Channel title"
                value={chatTitle}
                onChange={this.fieldUpdateHandler}
              />
            </FormGroup>
          ) : (
            <h2>{chat.title}</h2>
          )}
          {isAdmin && (
            <Dropdown
              isOpen={dropdownOpen}
              toggle={() => this.setState({ dropdownOpen: !dropdownOpen })}
            >
              <DropdownToggle>Options</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() =>
                    this.setState({ changingTitle: !changingTitle })
                  }
                >
                  {changingTitle ? "Done changing title" : "Change title"}
                </DropdownItem>
                <DropdownItem onClick={this.deleteHandler}>
                  Delete chat
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>

        <Chat
          project={project}
          stories={stories}
          participants={participants}
          user={user}
          chat={chat.chat ? chat.chat : []}
          saveMessage={this.saveMessageHandler}
          saveChat={saveChat}
          projectChat
        />
      </div>
    );
  }
}

export default withRouter(ChatPage);

import React from "react";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import "./Dependencies.scss";
import Explainer from "../../UI/Explainer/Explainer";
import { Link } from "react-router-dom";

const Dependencies = ({ stories, project, saveStory }) => {
  const owlTodo =
    project.json && project.json.owl
      ? project.json.owl
          .map((storyId) => stories.find((story) => story.id === storyId))
          .filter((sf) => sf.json.status !== "done")
      : [];

  const backlog =
    project.json && project.json.backlog
      ? project.json.backlog.map((storyId) => stories.find((story) => story.id === storyId))
      : [];

  const depTotal = owlTodo.concat(backlog);

  return (
    <div className="complexity">
      <h2>Step 6: Dependencies</h2>
      <Row>
        <Col lg="8">
          <div className="greybox">
            {backlog.map((story) => (
              <div className="dep-bar" key={story.id}>
                <div className="story__body">
                  <p>{story.json ? story.json.name : "..."}</p>
                  <DepSelect story={story} backLog={depTotal} saveStory={saveStory} />
                </div>
              </div>
            ))}
          </div>
        </Col>
        <Col lg="4">
          <Explainer>
            <p>
              <strong>Any dependencies?</strong>
            </p>
            <p>It's important to know which tasks are dependend upon other tasks.</p>
            <p>If a task can only be done after another task is finished, you can add that dependency here.</p>
            <p>P.s. Tasks can have multiple dependencies.</p>
            <p>Done? Then lets see if we can demote some tasks in step 7!</p>
            <Link className="btn btn-primary" to={`/project/${project.id}/demotion`}>
              {"➜ Step 7: Demotion"}
            </Link>
          </Explainer>
        </Col>
      </Row>
    </div>
  );
};

export const DepSelect = ({ story, saveStory, backLog }) => {
  const options = backLog
    ? backLog.filter((s) => s !== story).map((s) => ({ value: s.id, label: `${s.json.name}` }))
    : [];

  const onChangeHandler = (selectedOption, action) => {
    const json = { ...story.json, dependencies: selectedOption ? selectedOption.map((so) => so.value) : [] };
    saveStory({ ...story, json });
  };

  const dependencies = story.json.dependencies
    ? story.json.dependencies
        .map((s) => {
          const match = backLog.find((bls) => bls.id === s);
          if (match) {
            return { value: match.id, label: `${match.json.name}` };
          } else {
            return null;
          }
        })
        .filter((c) => c)
    : [];

  return (
    <Select
      isMulti
      placeholder="add dependencies"
      className="dep__select"
      options={options}
      value={dependencies}
      onChange={onChangeHandler}
    />
  );
};

export default Dependencies;

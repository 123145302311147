import React, { useRef, useState, useEffect } from "react";
import { MentionsInput, Mention } from "react-mentions";
import "./Chat.scss";
import Picker from "emoji-picker-react";
import AvatarPic from "../UI/AvatarPic/AvatarPic";
import { withRouter, useLocation } from "react-router-dom";
import generateUUID from "../../lib/GenerateUUID";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { IoMdMore } from "react-icons/io";
import { FaHashtag } from "react-icons/fa";
import { GoMention } from "react-icons/go";
import { firestore } from "../../firebase";

const Chat = ({
  user,
  participants,
  project,
  stories,
  chat,
  saveChat,
  history,
  projectChat,
  saveMessage,
}) => {
  const backlog =
    project.json && project.json.backlog
      ? project.json.backlog.map((storyId) =>
          stories.find((story) => story.id === storyId)
        )
      : [];

  const owl =
    project.json && project.json.owl
      ? project.json.owl.map((storyId) =>
          stories.find((story) => story.id === storyId)
        )
      : [];

  const done =
    project.json && project.json.done
      ? project.json.done.map((storyId) =>
          stories.find((story) => story.id === storyId)
        )
      : [];

  const users = participants
    .filter((u) => !!u.userId)
    .map((p) => ({
      id: p.userId,
      display: `${p.displayName}`,
    }));
  const tags = [...owl, ...backlog, ...done].map((story) => ({
    id: story.id,
    display: story.json.name,
  }));

  return (
    <ChatInner
      user={user}
      participants={participants}
      project={project}
      chat={chat}
      saveChat={saveChat}
      history={history}
      users={users}
      tags={tags}
      projectChat={projectChat}
      saveMessage={saveMessage}
    />
  );
};

const mentionHandler = (person, user, message, link, project) => {
  const subject = `${user?.displayName} mentioned you in project: ${project.name}`;
  const mention = `${user?.displayName} mentioned you in a One Week Leap chat message:</br></br>${message}</br></br>You can find this message here:</br><a href="${window.location}">${window.location}</a>`;

  firestore.collection("notifications").add({
    person,
    message: mention,
    dbMessage: message,
    subject,
    link,
    innerlink: "1",
    date: String(new Date()),
    unread: 1,
  });
};

const ChatInner = ({
  user,
  participants,
  project,
  chat,
  saveChat,
  history,
  users,
  tags,
  projectChat,
  saveMessage,
}) => {
  const [value, setValue] = useState("");
  const [emoji, setEmoji] = useState(false);
  const chatFieldRef = useRef(null);
  const emojiButtonRef = useRef(null);
  const emojiRef = useRef(null);
  const chatMessagesRef = useRef(null);
  const chatCountRef = useRef(0);
  const innerLink = useLocation();
  // eslint-disable-next-line
  const [selection, setSelection] = useState({ start: 0, end: 0 });

  const onEmojiClick = (event, emojiObject) => {
    setValue(
      `${value}:::__${emojiObject.names[0]}^:^__${emojiObject.emoji}:::^^^`
    );
    setEmoji(false);
    chatFieldRef.current.focus();
  };

  const addChar = (char) => {
    setValue(`${value} ${char}`);
    chatFieldRef.current.focus();
  };

  useEffect(() => {
    if (chatMessagesRef && chat && chatCountRef.current !== chat.length) {
      chatCountRef.current = chat.length;
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [chat]);

  useEffect(() => {
    const handleClick = (e) => {
      if (
        !emojiRef?.current?.contains(e.target) &&
        !emojiButtonRef?.current?.contains(e.target)
      ) {
        setEmoji(false);
      }
    };
    document.addEventListener("click", handleClick, false);
    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  }, [emoji]);

  const handleSelection = (event) => {
    setSelection({
      start: event.target.selectionStart,
      end: event.target.selectionEnd,
    });
  };

  const sendHandler = () => {
    var mentions = [],
      m,
      rx = /@@@__(.*?)\^@\^/g;
    while ((m = rx.exec(value)) !== null) {
      mentions.push(m[1]);
    }

    let newMessage = value
      .split("@@@__")
      .join('<span class="chat-user" data-id="')
      .split("^@^__")
      .join('">@')
      .split("@@@^^^")
      .join("</span>")
      .split("###__")
      .join(`<a class="chat-story" href="/project/${project.id}/task/`)
      .split("^#^__")
      .join('">')
      .split("###^^^")
      .join("</a>")
      .split(":::__")
      .join('<span class="chat-emoji" role="img" aria-labelledby="')
      .split("^:^__")
      .join('">')
      .split(":::^^^")
      .join("</span>")
      .replace(/(?:\r\n|\r|\n)/g, "<br>")
      .trim();

    if (newMessage !== "") {
      mentions.forEach((mention) => {
        mentionHandler(mention, user, newMessage, innerLink.pathname, project);
      });

      if (projectChat) {
        saveMessage(newMessage);
      } else {
        const newChat = chat ? [...chat] : [];
        newChat.push({
          message: newMessage,
          user: user?.id,
          name: `${user?.displayName}`,
          date: String(new Date()),
          guid: generateUUID(),
        });
        saveChat(newChat);
      }
      setValue("");
      chatFieldRef.current.focus();
    }
  };

  const chatClick = (e) => {
    const origin = e.target.closest("a");
    if (origin) {
      const internalLink = origin.href.split("/project/")[1];
      if (internalLink) {
        e.preventDefault();
        history.push(`/project/${internalLink}`);
      }
    }
  };

  const keyHandler = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      sendHandler();
    }
  };

  const updateMessage = (newMessage) => {
    if (projectChat) {
      saveMessage(newMessage.message, newMessage.guid);
    } else {
      const newChat = [...chat];
      let matchIndex = newChat.findIndex((c) => c.guid === newMessage.guid);
      if (matchIndex > -1) {
        newChat[matchIndex] = newMessage;
      }
      saveChat(newChat);
    }
  };

  return (
    <div className="chat">
      <div className="chat__messages" onClick={chatClick} ref={chatMessagesRef}>
        {(!chat || chat.length === 0) && <h5>No messages yet!</h5>}
        {chat &&
          chat.map((message) => {
            const self = message.user === user?.id ? true : false;
            const userMatch = participants.find(
              (p) => p.userId === message.user
            );
            return (
              <ChatItem
                key={message.guid}
                self={self}
                userMatch={userMatch}
                message={message}
                updateMessage={updateMessage}
              />
            );
          })}
      </div>
      <div className="chat__field">
        <div className="chat__options">
          <div
            className="chat__button"
            onClick={() => setEmoji(true)}
            ref={emojiButtonRef}
          >
            <span role="img" aria-labelledby="smiley">
              🙂
            </span>
          </div>
          <div
            className="emojiWrapper"
            ref={emojiRef}
            style={{ display: emoji ? "block" : "none" }}
          >
            <Picker onEmojiClick={onEmojiClick} />
          </div>
          <div className="chat__button" onClick={() => addChar("@")}>
            <GoMention />
          </div>
          <div className="chat__button" onClick={() => addChar("#")}>
            <FaHashtag />
          </div>
        </div>
        <MentionsInput
          className="chat-item"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={keyHandler}
          onSelect={handleSelection}
          inputRef={chatFieldRef}
          style={{
            backgroundColor: "white",
          }}
        >
          <Mention
            trigger="@"
            markup="@@@____id__^@^____display__@@@^^^"
            data={users}
            style={{
              backgroundColor: "#daf4fa",
            }}
          />
          <Mention
            trigger="#"
            markup="###____id__^#^____display__###^^^"
            data={tags}
            style={{
              backgroundColor: "#ceffd1",
            }}
          />
          <Mention
            trigger="::"
            markup=":::____id__^:^____display__:::^^^"
            data={[]}
          />
        </MentionsInput>
      </div>
    </div>
  );
};

const ChatItem = ({ self, userMatch, message, updateMessage }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const deleteHandler = () => {
    setDropdownOpen(false);
    const newMessage = {
      ...message,
      message: '<span class="chat-deleted">deleted</span>',
    };
    updateMessage(newMessage);
  };

  return (
    <div className={"chat-message" + (self ? " chat-message--self" : "")}>
      <div className="chat-message__inner">
        {!self && <AvatarPic src={userMatch ? userMatch.avatar : null} />}
        <div className="chat-message__cloud">
          {self && !message.guid.includes("notsaved") && (
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle className="settings-button" color="link">
                <IoMdMore />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={deleteHandler}>Delete</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          <div className="chat-message__content">
            <p dangerouslySetInnerHTML={{ __html: message.message }} />
          </div>
          <p className="chat-message__meta">
            {`${message.name} - ${new Date(message.date).toLocaleDateString(
              undefined,
              {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }
            )}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Chat);

/*
class ChatInner extends Component {
  state = {
    value: "",
    emoji: false,
    selection: { start: 0, end: 0 },
  };

  chatFieldRef = React.createRef();
  emojiButtonRef = React.createRef();
  emojiRef = React.createRef();
  chatMessagesRef = React.createRef();

  componentDidMount() {
    document.addEventListener("click", this.handleClick, false);
    if (this.chatMessagesRef) {
      this.chatMessagesRef.current.scrollTop = this.chatMessagesRef.current.scrollHeight;
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.emojiRef.current.contains(e.target) && !this.emojiButtonRef.current.contains(e.target)) {
      this.setState({ emoji: false });
    }
  };

  onEmojiClick = (event, emojiObject) => {
    this.setState({
      value: `${this.state.value}:::__${emojiObject.names[0]}^:^__${emojiObject.emoji}:::^^^`,
      emoji: false,
    });
    this.chatFieldRef.current.focus();
  };

  handleSelection = (event) => {
    this.setState({
      selection: {
        start: event.target.selectionStart,
        end: event.target.selectionEnd,
      },
    });
  };

  sendHandler = () => {
    const { user, project, chat, saveChat, projectChat, saveMessage } = this.props;
    let newMessage = this.state.value
      .split("@@@__")
      .join('<span class="chat-user" data-id="')
      .split("^@^__")
      .join('">@')
      .split("@@@^^^")
      .join("</span>")
      .split("###__")
      .join(`<a class="chat-story" href="/project/${project.id}/task/`)
      .split("^#^__")
      .join('">')
      .split("###^^^")
      .join("</a>")
      .split(":::__")
      .join('<span class="chat-emoji" role="img" aria-labelledby="')
      .split("^:^__")
      .join('">')
      .split(":::^^^")
      .join("</span>")
      .replace(/(?:\r\n|\r|\n)/g, "<br>")
      .trim();

    if (newMessage !== "") {
      if (projectChat) {
        saveMessage(newMessage);
      } else {
        const newChat = chat ? [...chat] : [];
        newChat.push({
          message: newMessage,
          user: user.id,
          name: `${user.data.firstName} ${user.data.lastName}`,
          date: new Date(),
          guid: generateUUID(),
        });
        saveChat(newChat);
      }
      this.setState({ value: "" });
      this.chatFieldRef.current.focus();
    }
  };

  chatClick = (e) => {
    const origin = e.target.closest("a");
    if (origin) {
      const internalLink = origin.href.split("/project/")[1];
      if (internalLink) {
        e.preventDefault();
        this.props.history.push(`/project/${internalLink}`);
      }
    }
  };

  keyHandler = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      this.props.sendHandler();
    }
  };

  updateMessage = (newMessage) => {
    const { chat, saveChat, projectChat, saveMessage } = this.props;

    if (projectChat) {
      saveMessage(newMessage.message, newMessage.guid);
    } else {
      const newChat = [...chat];
      let matchIndex = newChat.findIndex((c) => c.guid === newMessage.guid);
      if (matchIndex > -1) {
        newChat[matchIndex] = newMessage;
      }
      saveChat(newChat);
    }
  };

  render() {
    const { user, participants, chat, users, tags } = this.props;

    return (
      <div className="chat">
        <div className="chat__messages" onClick={this.chatClick} ref={this.chatMessagesRef}>
          {(!chat || chat.length === 0) && <h5>No messages yet!</h5>}
          {chat &&
            chat.map((message) => {
              const self = message.user === user.id ? true : false;
              const userMatch = participants.find((p) => p.userId === message.user);
              return (
                <ChatItem
                  key={message.guid}
                  self={self}
                  userMatch={userMatch}
                  message={message}
                  updateMessage={this.updateMessage}
                />
              );
            })}
        </div>
        <div className="chat__field">
          <MentionsInput
            className="chat-item"
            value={this.state.value}
            onChange={(e) => this.setState({ value: e.target.value })}
            onKeyPress={this.keyHandler}
            onSelect={this.handleSelection}
            inputRef={this.chatFieldRef}
            style={{
              backgroundColor: "white",
            }}
          >
            <Mention
              trigger="@"
              markup="@@@____id__^@^____display__@@@^^^"
              data={users}
              style={{
                backgroundColor: "#daf4fa",
              }}
            />
            <Mention
              trigger="#"
              markup="###____id__^#^____display__###^^^"
              data={tags}
              style={{
                backgroundColor: "#ceffd1",
              }}
            />
            <Mention trigger="::" markup=":::____id__^:^____display__:::^^^" data={[]} />
          </MentionsInput>
          <div className="emojiToggle" onClick={() => this.setState({ emoji: false })} ref={this.emojiButtonRef}>
            <span role="img" aria-labelledby="smiley">
              🙂
            </span>
          </div>
          <div className="emojiWrapper" ref={this.emojiRef} style={{ display: this.state.emoji ? "block" : "none" }}>
            <Picker onEmojiClick={this.onEmojiClick} />
          </div>
        </div>
      </div>
    );
  }
}
*/

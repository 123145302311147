import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Spinner } from "reactstrap";
import "./NewNote.scss";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const NewNote = ({ onSubmit, project, notes, history }) => {
  const [newNoteText, setNewNoteText] = useState("");
  const [busy, setBusy] = useState(false);
  const inputEl = useRef(null);

  const submitHandler = (e) => {
    e.preventDefault();
    if (busy) {
      return;
    }

    if (!newNoteText) {
      toast.warning("Enter a title for this new note");
      return;
    }

    if (notes.filter((note) => note.title.toLowerCase() === newNoteText.toLowerCase()).length > 0) {
      toast.warning("There's already a note with this title!");
      return;
    }

    const newId = uuidv4();
    const newNote = {
      id: newId,
      title: newNoteText,
      content: "",
    };

    const newNotes = [...notes];
    newNotes.push(newNote);

    setNewNoteText("");
    inputEl.current.focus();
    onSubmit(newNotes);
    setTimeout(() => {
      history.push(`/project/${project.id}/note/${newId}`);
    }, 200);
  };

  return (
    <div className="new-note">
      <Form onSubmit={submitHandler} className="new-note__form">
        <FormGroup>
          <Input
            type="text"
            name="newNoteText"
            id="newNoteText"
            ref={inputEl}
            value={newNoteText}
            onChange={(e) => setNewNoteText(e.target.value)}
          />
        </FormGroup>
        {busy ? <Spinner color="primary" /> : <Button color="primary">Add note</Button>}
      </Form>
    </div>
  );
};

export default withRouter(NewNote);

import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [busy, setBusy] = useState(false);

  const loginHandler = (event) => {
    event.preventDefault();

    if (email.length > 0 && !busy) {
      setBusy(true);

      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          toast.success("We've sent the password reset link to your email!");
          setBusy(false);
        })
        .catch(() => {
          toast.warning("We don't recognise this email address!");
          setBusy(false);
        });
    } else if (!email) {
      toast.warning("Enter your email");
    }
  };

  return (
    <div>
      <h3>Forgot password?</h3>
      <p>No problem! Enter your email address below and we will sent you a password reset link</p>
      <Form onSubmit={loginHandler}>
        <FormGroup>
          <Label for="exampleEmail">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="E-mail address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        {busy ? <Spinner color="primary" /> : <Button color="primary">Reset password</Button>}
        <Link to="/login">
          <Button color="link">Login</Button>
        </Link>
      </Form>
    </div>
  );
};

export default ForgotPassword;

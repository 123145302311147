import React from "react";
import { Switch, Route, withRouter, Link } from "react-router-dom";
import Div100vh from "react-div-100vh";
import Header from "./components/Header/Header";
import Register from "./components/Register/Register";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import AppBody from "./components/UI/AppBody/AppBody";
import background from "./img/owl-background.svg";
import { Button } from "reactstrap";

const LoggedOut = (props) => {
  const location = props.location.pathname;
  const loginHandler = () => {
    if (
      location.includes("register") ||
      location.includes("forgot-password") ||
      location.includes("confirm-email") ||
      location.includes("reset-password") ||
      location.includes("login")
    ) {
      props.history.push("/projects");
    }
  };

  let tab = "login";
  if (location.includes("register") || location.includes("confirm-email")) {
    tab = "register";
  }

  return (
    <Div100vh>
      <div className="app">
        <Header loggedIn={false} />
        <div className="app-body app-body--reverse">
          <AppBody>
            <img src={background} className="owl-background" alt="background" />
            <div className="logincard">
              <div className="logincard__content">
                <div className="logincard__header">
                  <Link
                    className={`logincard__tab logincard__tab--${tab === "login" ? "active" : "inactive"}`}
                    to="/login"
                  >
                    Login
                  </Link>
                  <Link
                    className={`logincard__tab logincard__tab--${tab === "register" ? "active" : "inactive"}`}
                    to="/register"
                  >
                    Create Free account!
                  </Link>
                </div>
                <div className="logincard__body">
                  <Switch>
                    <Route path="/register/:source" render={(props) => <Register {...props} login={loginHandler} />} />
                    <Route path="/register" render={(props) => <Register {...props} login={loginHandler} />} />
                    <Route path="/login" render={(props) => <Login {...props} login={loginHandler} />} />
                    <Route path="/forgot-password" render={(props) => <ForgotPassword {...props} />} />
                    <Route path="/" render={(props) => <Login {...props} login={loginHandler} />} />
                  </Switch>
                </div>
              </div>
            </div>
          </AppBody>
        </div>
      </div>
    </Div100vh>
  );
};

export default withRouter(LoggedOut);

import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Alert,
  ModalFooter,
} from "reactstrap";
import "./ProjectSettings.scss";
import TeamMember from "./TeamMember";
import emailIsValid from "../../../lib/ValidateEmail";
import ChipEdit from "./ChipEdit";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { confirmAlert } from "react-confirm-alert";
import { withRouter } from "react-router-dom";
import { firestore } from "../../../firebase";

const ProjectSettings = ({ project, participants, user, chips, saveProject, saveProjectDelay, history }) => {
  const userParticipant = participants?.find((p) => p.userId === user?.id);
  const isAdmin = userParticipant && userParticipant.administrator === 1 ? true : false;

  const [searchPersonText, setSearchPersonText] = useState("");
  const [busy, setBusy] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [projectName, setProjectName] = useState(project.name);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const inviteSearchChange = (e) => {
    let { value } = e.target;
    value = value.replace(/\s/g, "");
    setSearchPersonText(value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (busy) {
      return;
    }

    if (!searchPersonText) {
      toast.warning("Enter the email address of the person you want to invite to your team");
      return;
    }

    if (!emailIsValid(searchPersonText)) {
      toast.warning("This doesn't appear to be a valid e-mail address");
      return;
    }

    if (participants.find((p) => p.email === searchPersonText)) {
      toast.warning("Already on your team!");
      return;
    }

    if (participants.find((p) => p.inviteEmail && p.inviteEmail.toLowerCase() === searchPersonText.toLowerCase())) {
      toast.warning("Invitation to this person has already been sent!");
      return;
    }

    firestore.doc(`projects/${project.id}/participantInvites/${searchPersonText}`).set({ email: searchPersonText });

    saveProject({
      participants: [...project.participants, { inviteEmail: searchPersonText }],
    });

    setSearchPersonText("");
    toast.success(`Added ${searchPersonText} to team!`);
  };

  const documentUpdateHandler = (event, editor) => {
    const data = editor.getData();
    //console.log({ event, editor, data });
    const json = {
      ...project.json,
      defaultText: data,
    };

    saveProjectDelay({ ...project, json });
  };

  const deleteHandler = () => {
    confirmAlert({
      title: `You sure?`,
      message: `Really delete this project? This cannot be undone`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            saveProject({ active: 0 });
            toast.warning("Project deleted");
            history.push("/");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const keyPressHandler = (e) => {
    if (e.which === 13) {
      saveProjectName();
    }
  };

  const saveProjectName = () => {
    if (!projectName) {
      return;
    }
    saveProject({
      name: projectName,
    });
    setModal(false);
  };

  return (
    <div className="project-settings">
      <Modal isOpen={modal} toggle={toggleModal} className="">
        <ModalHeader toggle={toggleModal}>Change project name</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="projectName">Change your projects name</Label>
            <Input
              type="text"
              name="projectName"
              id="projectName"
              placeholder="Project name"
              value={projectName}
              onKeyPress={keyPressHandler}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          {busy ? (
            <Spinner color="primary" />
          ) : (
            <>
              <Button color="primary" onClick={saveProjectName}>
                Save project name
              </Button>{" "}
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
      <div className="project-settings__header">
        <h2>Project settings</h2>
        {isAdmin && (
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle>admin</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={toggleModal}>Change project name</DropdownItem>
              <DropdownItem onClick={deleteHandler}>Delete project</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
      <br />
      <Row>
        <Col lg="6">
          <h3>Project tags</h3>
          <ChipEdit chips={chips} project={project} saveProject={saveProject} />
          <br />
          <h3>Default task text</h3>
          <CKEditor
            editor={ClassicEditor}
            data={project?.json?.defaultText || ""}
            className="main-editor"
            config={{
              toolbar: {
                items: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "blockQuote",
                  "insertTable",
                  "|",
                  "undo",
                  "redo",
                ],
              },
              table: {
                contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
              },
              language: "en",
            }}
            /*
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}*/
            onChange={documentUpdateHandler} /*
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}*/
          />
        </Col>
        <Col lg="6">
          <h3>Project team</h3>
          <div className="project-settings__team">
            {participants?.map((participant) => (
              <TeamMember
                participant={participant}
                key={participant.userId}
                admin={isAdmin}
                user={user}
                saveProject={saveProject}
                project={project}
              />
            ))}
          </div>
          <br />
          {isAdmin && (
            <>
              <h4>Add people to your team</h4>
              <Form onSubmit={submitHandler} className="new-note__form">
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="E-mail address"
                    name="searchPersonText"
                    id="searchPersonText"
                    value={searchPersonText}
                    onChange={inviteSearchChange}
                  />
                </FormGroup>
                {busy ? (
                  <Spinner color="primary" />
                ) : (
                  <Button color="primary" disabled={!searchPersonText || searchPersonText.length < 4}>
                    Add person
                  </Button>
                )}
              </Form>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(ProjectSettings);

import React, { Component } from "react";
import SideBar from "../UI/SideBar/SideBar";
import { Link } from "react-router-dom";
import Loader from "../UI/Loader/Loader";
import { ListGroup, ListGroupItem, Badge, InputGroup, Input } from "reactstrap";
import NewNote from "../UI/NewNote/NewNote";
import Story from "../UI/Story/Story";
import { FaCalendarWeek, FaBrain, FaClock, FaSearch, FaSlackHash, FaCubes } from "react-icons/fa";
import { MdLowPriority } from "react-icons/md";
import { FiSettings } from "react-icons/fi";
import { GiThink } from "react-icons/gi";
import { SiAdblock } from "react-icons/si";
import { BsArrowUpDown, BsFillKanbanFill } from "react-icons/bs";
import { AiFillMediumSquare } from "react-icons/ai";
import NewChat from "../UI/NewChat/NewChat";
import { IoIosDocument } from "react-icons/io";

class ProjectSideBar extends Component {
  state = {
    searchText: "",
    searchActive: 0,
  };

  setSearchText = (e) => {
    clearTimeout(this.searchTimer);
    this.setState({ searchText: e.target.value });
    this.searchTimer = setTimeout(this.setSearchCommand, 300);
  };

  setSearchCommand = () => {
    this.props.setSearchCommand(this.state.searchText);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.searchActive !== state.searchActive) {
      return { searchActive: props.searchActive, searchText: "" };
    } else {
      return state;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.searchTimer);
  }

  render() {
    const { searchText } = this.state;
    const {
      loading,
      project,
      step,
      stepSubId,
      notes,
      saveNotes,
      saveChats,
      stories,
      user,
      participants,
      saveProject,
      saveStory,
      chatRooms,
    } = this.props;
    if (loading || !project) {
      return (
        <SideBar>
          <Loader />
        </SideBar>
      );
    }

    const backlog =
      project.json && project.json.backlog
        ? project.json.backlog.map((storyId) => stories.find((story) => story.id === storyId))
        : [];

    const doingStories = project?.json?.owl
      ? project.json.owl
          .map((storyId) => stories?.find((story) => story?.id === storyId))
          .filter((s) => s.json?.status === "doing" && s.json?.person === user?.id)
      : [];

    const moscowCount = backlog.filter((story) => !story.json.moscow).length;
    const complexityCount = backlog.filter((story) => !story.json.complexity).length;
    const timeCount = backlog?.filter((story) => !story.json.hours)?.length;
    const categorizeCount = backlog?.filter((story) => !story.json.category)?.length;

    return (
      <SideBar title={project?.name?.charAt(0).toUpperCase() + project?.name?.slice(1)}>
        <InputGroup className="search-group">
          <div className="search-group__icon">
            <FaSearch />
          </div>

          <Input value={searchText} onChange={this.setSearchText} />
        </InputGroup>
        <ListGroup>
          <Link to={`/project/${project.id}/owl`}>
            <ListGroupItem active={step === "owl" ? true : false} action>
              <div className="sidebar__item">
                <FaCalendarWeek className="sidebar__icon" />
                One Week Leap
              </div>
            </ListGroupItem>
          </Link>
          <Link to={`/project/${project.id}/overview`}>
            <ListGroupItem active={step === "overview" ? true : false} action>
              <div className="sidebar__item">
                <BsFillKanbanFill className="sidebar__icon" />
                Project overview
              </div>
            </ListGroupItem>
          </Link>
          <Link to={`/project/${project.id}/settings`}>
            <ListGroupItem active={step === "settings" ? true : false} action>
              <div className="sidebar__item">
                <FiSettings className="sidebar__icon" />
                Settings and team
              </div>
            </ListGroupItem>
          </Link>
        </ListGroup>
        <br />
        {doingStories.length > 0 && (
          <>
            <h4>Currently doing</h4>
            {doingStories.map((s) => (
              <Story
                key={s.id}
                story={s}
                participants={participants}
                project={project}
                preFix="sidebar"
                small
                stories={stories}
                saveProject={saveProject}
                user={user}
                saveStory={saveStory}
              />
            ))}
          </>
        )}
        {(participants?.length > 1 || chatRooms?.length > 0) && (
          <>
            <br />
            <h4>Chat channels</h4>
            <ListGroup>
              {chatRooms.map((chat) => (
                <Link key={chat.id} to={`/project/${project.id}/chat/${chat.id}`}>
                  <ListGroupItem active={step === "chat" && stepSubId === chat.id ? true : false} action>
                    <div className="sidebar__item">
                      <FaSlackHash className="sidebar__icon" />
                      {chat.title}
                    </div>
                    {chat.lastMessage && chat.lastRead && parseInt(chat.lastMessage) > parseInt(chat.lastRead) && (
                      <Badge color="info">{parseInt(chat.lastMessage) - parseInt(chat.lastRead)}</Badge>
                    )}
                  </ListGroupItem>
                </Link>
              ))}
            </ListGroup>
            <NewChat project={project} chats={chatRooms} onSubmit={saveChats} />
          </>
        )}

        <br />
        <h4>8 steps</h4>
        <ListGroup>
          <Link to={`/project/${project.id}/braindump`}>
            <ListGroupItem active={step === "braindump" ? true : false} action>
              <div className="sidebar__item">
                <GiThink className="sidebar__icon" />
                1. Brain-dump
              </div>
            </ListGroupItem>
          </Link>
          <Link to={`/project/${project.id}/categorize`}>
            <ListGroupItem active={step === "categorize" ? true : false} action>
              <div className="sidebar__item">
                <FaCubes className="sidebar__icon" />
                2. Categorize
              </div>
              {categorizeCount > 0 && <Badge color="info">{categorizeCount}</Badge>}
            </ListGroupItem>
          </Link>
          <Link to={`/project/${project.id}/moscow`}>
            <ListGroupItem active={step === "moscow" ? true : false} action>
              <div className="sidebar__item">
                <AiFillMediumSquare className="sidebar__icon" />
                3. MoScoW
              </div>
              {moscowCount > 0 && <Badge color="info">{moscowCount}</Badge>}
            </ListGroupItem>
          </Link>
          <Link to={`/project/${project.id}/complexity`}>
            <ListGroupItem active={step === "complexity" ? true : false} action>
              <div className="sidebar__item">
                <FaBrain className="sidebar__icon" />
                4. Complexity
              </div>
              {complexityCount > 0 && <Badge color="info">{complexityCount}</Badge>}
            </ListGroupItem>
          </Link>
          <Link to={`/project/${project.id}/time`}>
            <ListGroupItem active={step === "time" ? true : false} action>
              <div className="sidebar__item">
                <FaClock className="sidebar__icon" />
                5. Completion time
              </div>
              {timeCount > 0 && <Badge color="info">{timeCount}</Badge>}
            </ListGroupItem>
          </Link>
          <Link to={`/project/${project.id}/dependencies`}>
            <ListGroupItem active={step === "dependencies" ? true : false} action>
              <div className="sidebar__item">
                <SiAdblock className="sidebar__icon" />
                6. Dependencies
              </div>
            </ListGroupItem>
          </Link>
          <Link to={`/project/${project.id}/demotion`}>
            <ListGroupItem active={step === "demotion" ? true : false} action>
              <div className="sidebar__item">
                <MdLowPriority className="sidebar__icon" />
                7. Demotion
              </div>
            </ListGroupItem>
          </Link>
          <Link to={`/project/${project.id}/prioritize`}>
            <ListGroupItem active={step === "prioritize" ? true : false} action>
              <div className="sidebar__item">
                <BsArrowUpDown className="sidebar__icon" />
                8. Prioritize
              </div>
            </ListGroupItem>
          </Link>
        </ListGroup>
        <br />
        <h4>Notes</h4>
        <ListGroup>
          {notes.map((note) => (
            <Link key={note.id} to={`/project/${project.id}/note/${note.id}`}>
              <ListGroupItem active={step === "note" && stepSubId === note.id ? true : false} action>
                <div className="sidebar__item">
                  <IoIosDocument className="sidebar__icon" />
                  {note.title}
                </div>
              </ListGroupItem>
            </Link>
          ))}
        </ListGroup>
        <NewNote project={project} notes={notes} onSubmit={saveNotes} />
      </SideBar>
    );
  }
}

export default ProjectSideBar;

export const initialProject = {
  backlog: [],
  owl: [],
  done: [],
  defaultText:
    "<h2>Task notes</h2><p>…</p><h3>Thought provoking questions</h3><ul><li>How could I do this task in ¼ of the time and energy?</li><li>Have others done something similar to this task and how did they do it?</li><li>Is there any way I can delegate this task to someone else?</li><li>Is there something easier/faster I can do to make this task obsolete?</li></ul>",
};

export const initialStory = {};

export const primaryColor = "#007bff";

import React from "react";
import { GrFormClose } from "react-icons/gr";
import Story from "../../UI/Story/Story";
import "./ProjectSearch.scss";

const ProjectSearch = ({ searchCommand, project, stories, deactivate, participants, saveProject, user, saveStory }) => {
  const backlog =
    project.json && project.json.backlog
      ? project.json.backlog.map((storyId) => stories.find((story) => story.id === storyId))
      : [];

  const owl =
    project.json && project.json.owl
      ? project.json.owl.map((storyId) => stories.find((story) => story.id === storyId))
      : [];

  const done =
    project.json && project.json.done
      ? project.json.done.map((storyId) => stories.find((story) => story.id === storyId))
      : [];
  const matchingStories = [...owl, ...backlog, ...done].filter((story) =>
    Object.values(story.json).some((val) =>
      val
        ? Array.isArray(val)
          ? val.join(" ").toLowerCase().includes(searchCommand)
          : JSON.stringify(val).toLowerCase().includes(searchCommand)
        : null
    )
  );

  return (
    <div className="project-search project-search--active">
      <div className="project-search__backdrop" onClick={deactivate} />
      <div className="project-search__inner">
        <div className="project-search__close" onClick={deactivate}>
          <GrFormClose />
        </div>
        <h3>Search results</h3>
        {matchingStories && matchingStories.length > 0 ? (
          <>
            <div className="greybox">
              {matchingStories.map((story) => (
                <Story
                  key={story.id}
                  preFix="search"
                  story={story}
                  project={project}
                  participants={participants}
                  stories={stories}
                  saveProject={saveProject}
                  user={user}
                  saveStory={saveStory}
                  onClick={deactivate}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="project-search__no-results">
            <p>No tasks found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectSearch;

import React, { useState } from "react";
import AvatarPic from "../../UI/AvatarPic/AvatarPic";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { IoMdMore } from "react-icons/io";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const TeamMember = ({ participant, admin, project, saveProject, user }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [busy, setBusy] = useState(false);

  const deleteHandler = () => {
    if (user.id === participant.userId) {
      toast.warning("Cant remove yourself from a project");
    } else {
      confirmAlert({
        title: `You sure?`,
        message: `Really remove ${
          participant.displayName || participant.inviteEmail
        } from your team?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              saveProject({
                participantIds: [
                  ...project.participantIds.filter(
                    (p) => p !== participant.userId
                  ),
                ],
                participants: [
                  ...project.participants.filter(
                    (p) => p.userId !== participant.userId
                  ),
                ],
              });
              toast.success(
                `Removed ${
                  participant.displayName || participant.inviteEmail
                } from your team!`
              );
              setBusy(true);
            },
          },
          {
            label: "No",
          },
        ],
      });
    }
  };

  const changeAdmin = (administrator) => {
    if (user.id === participant.userId) {
      toast.warning("Cant remove your own admin status!");
    } else {
      const newParticipants = [...project.participants];
      const newParticipantIndex = newParticipants.findIndex(
        (p) => p.userId === participant.userId
      );
      newParticipants[newParticipantIndex] = {
        ...newParticipants[newParticipantIndex],
        administrator,
      };

      saveProject({
        participants: newParticipants,
      });

      toast.success(
        administrator === 1
          ? `Gave ${participant.displayName} admin rights!`
          : `Removed admin privileges of ${participant.displayName}!`
      );
    }
  };

  return (
    <div className="teammember">
      <AvatarPic src={participant.avatar} />
      <div className="teammember__info">
        <p className="teammember__name">
          {participant.email
            ? `${participant.displayName} ${
                participant.administrator === 1 ? " (administrator)" : ""
              }`
            : "invite pending"}
        </p>
        <p className="teammember__email">{`${
          participant.email || participant.inviteEmail
        }`}</p>
      </div>
      {admin && (
        <div className="teammember__options">
          {busy ? (
            <Spinner color="primary" />
          ) : (
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle className="settings-button" color="link">
                <IoMdMore />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={deleteHandler}>
                  Remove from team
                </DropdownItem>
                {participant?.email && (
                  <>
                    {participant.administrator === 1 ? (
                      <DropdownItem onClick={() => changeAdmin(0)}>
                        Revoke administrator privileges
                      </DropdownItem>
                    ) : (
                      <DropdownItem onClick={() => changeAdmin(1)}>
                        Make administrator
                      </DropdownItem>
                    )}
                  </>
                )}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      )}
    </div>
  );
};

export default TeamMember;

import React, { createContext, useContext, useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import Header from "../components/Header/Header";
import AppBody from "../components/UI/AppBody/AppBody";
import Loader from "../components/UI/Loader/Loader";
import { auth, firestore } from "../firebase";

export const UserContext = createContext({ user: null });

const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  const source = window.localStorage.getItem("source");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = firestore.doc(`users/${userAuth.uid}`);
        const snapshot = await userRef.get();
        if (!snapshot.exists) {
          const { email, displayName, photoURL } = userAuth;
          try {
            await userRef.set({
              displayName: window.firstName || displayName,
              email,
              avatar: photoURL,
              source,
            });
          } catch (error) {
            console.error("Error: couldn't sign up! Please try again!");
          }
        }
      }
      const latestConfirmEmail = window.localStorage.getItem("confirmEmail");
      if (userAuth && !userAuth.emailVerified && latestConfirmEmail !== userAuth.email) {
        window.localStorage.setItem("confirmEmail", userAuth.email);
        userAuth.sendEmailVerification();
      }
      setUser(userAuth);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [source, user]);

  if (loading) {
    return (
      <Div100vh>
        <div className="app">
          <Header loading />
          <div className="app-body">
            <AppBody>
              <Loader />
            </AppBody>
          </div>
        </div>
      </Div100vh>
    );
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const user = useContext(UserContext);
  return user;
};

export default UserProvider;

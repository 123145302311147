import React from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import "./Explainer.scss";

const Explainer = ({ children }) => {
  return (
    <div className="explainer">
      <div className="explainer__icon">
        <BsFillInfoCircleFill />
      </div>

      {children}
    </div>
  );
};

export default Explainer;

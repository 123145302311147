import React, { useState } from "react";
import "./Story.scss";
import { Link } from "react-router-dom";
import { Tooltip, Badge } from "reactstrap";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoMdCheckboxOutline, IoMdCheckbox } from "react-icons/io";
import { FaClock, FaBrain, FaFlag } from "react-icons/fa";
import { SiAdblock } from "react-icons/si";
import AvatarPic from "../AvatarPic/AvatarPic";
import { ContextMenu, MenuItem, ContextMenuTrigger, SubMenu } from "react-contextmenu";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

const Story = ({
  story,
  participants,
  project,
  preFix,
  small = false,
  stories,
  saveProject,
  user,
  saveStory,
  onClick,
}) => {
  const [statusIconOpen, setStatusIconOpen] = useState(false);
  const statusToggle = () => setStatusIconOpen((prevState) => !prevState);

  const [prioOpen, setPrioOpen] = useState(false);
  const prioToggle = () => setPrioOpen((prevState) => !prevState);

  const [personOpen, setPersonOpen] = useState(false);
  const personToggle = () => setPersonOpen((prevState) => !prevState);

  const [timeIconOpen, setTimeOpen] = useState(false);
  const timeToggle = () => setTimeOpen((prevState) => !prevState);

  const [complexityIconOpen, setComplexityOpen] = useState(false);
  const complexityToggle = () => setComplexityOpen((prevState) => !prevState);

  const [depIconOpen, setDepOpen] = useState(false);
  const depToggle = () => setDepOpen((prevState) => !prevState);

  let StatusIcon = MdCheckBoxOutlineBlank;
  let statusText = "To do!";
  let statusColor = "#757575";

  if (story.json.status && story.json.status === "doing") {
    StatusIcon = IoMdCheckboxOutline;
    statusText = "Doing!";
    statusColor = "#007bff";
  } else if (story.json.status && story.json.status === "done") {
    StatusIcon = IoMdCheckbox;
    statusText = "Done!";
    statusColor = "#28a745";
  }

  let timeColor = "#17a2b8";
  if (story.json.hours && parseInt(story.json.hours) > 8) {
    timeColor = "#dc3545";
  } else if (story.json.hours && parseInt(story.json.hours) > 4) {
    timeColor = "#ffc107";
  }

  let timeLabel = story.json.hours;
  if (parseInt(timeLabel) === 0) {
    timeLabel = `< ${parseInt(timeLabel) + 1}`;
  } else if (parseInt(timeLabel) === 9) {
    timeLabel = `> ${parseInt(timeLabel) - 1}`;
  }

  let complexityColor = "#17a2b8";
  if (story.json.complexity && parseInt(story.json.complexity) > 8) {
    complexityColor = "#dc3545";
  } else if (story.json.complexity && parseInt(story.json.complexity) > 6) {
    complexityColor = "#ffc107";
  }

  const dependencies = story.json.dependencies
    ? story.json.dependencies
        .map((s) => {
          const match = stories.find((bls) => bls.id === s);
          if (match.json.status && match.json.status === "done") {
            return null;
          }
          return match.json.name;
        })
        .filter((c) => c)
    : null;

  const allChips = project.json.chips ? project.json.chips : [];
  const chips = (
    <div className="story__chips">
      {story.json &&
        story.json.chips &&
        story.json.chips
          .filter((c) => allChips.includes(c))
          .map((chip) => {
            const chipColor = project.json.chipColors ? project.json.chipColors.find((c) => c.chip === chip) : null;
            return (
              <Badge
                key={chip}
                style={{
                  backgroundColor: chipColor ? chipColor.color : "#6c757d",
                  color: chipColor && chipColor.isLight ? "black" : "white",
                }}
              >
                {chip}
              </Badge>
            );
          })}
    </div>
  );

  const moscowColors = {
    m: "success",
    s: "info",
    w: "warning",
  };

  const moscowLabels = {
    m: "Must have",
    s: "Should have",
    w: "Would like to have",
  };

  const assignee =
    participants.length > 1 && story.json.person ? participants.find((p) => p.userId === story.json.person) : null;

  let iconCount = 1;
  if (dependencies && dependencies.length > 0) {
    iconCount++;
  }
  if (story.json.complexity) {
    iconCount++;
  }
  if (story.json.hours) {
    iconCount++;
  }
  if (story.json.flag) {
    iconCount++;
  }
  if (story.json.moscow) {
    iconCount++;
  }
  if (assignee) {
    iconCount++;
  }

  const deleteHandler = () => {
    confirmAlert({
      title: "Really delete this task?",
      message: "This cannot be undone",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const owl =
              project.json && project.json.owl ? project.json.owl.filter((storyId) => storyId !== story.id) : [];

            const done =
              project.json && project.json.done ? project.json.done.filter((storyId) => storyId !== story.id) : [];

            const backlog =
              project.json && project.json.backlog
                ? project.json.backlog.filter((storyId) => storyId !== story.id)
                : [];

            const json = {
              ...project.json,
              owl,
              done,
              backlog,
            };

            toast.warning("Task deleted");
            saveProject({ ...project, json });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const assignSelfHandler = () => {
    const json = { ...story.json, person: user.id };
    saveStory({ ...story, json });
  };

  const flagHandler = () => {
    const json = { ...story.json, flag: !story.json.flag };
    saveStory({ ...story, json });
  };

  const setChip = (chip) => {
    const chips = story?.json?.chips ? [...story.json.chips] : [];
    const index = chips.indexOf(chip);
    if (index > -1) {
      chips.splice(index, 1);
    } else {
      chips.push(chip);
    }
    const json = { ...story.json, chips };
    saveStory({ ...story, json });
  };

  const setCat = (cat) => {
    const json = { ...story.json, category: cat };
    saveStory({ ...story, json });
  };

  const matchingCategory =
    project.json?.categories && story.json.category
      ? project.json?.categories?.find((c) => c.name === story.json.category)
      : null;

  return (
    <>
      <Link
        onClick={onClick}
        className={"story" + (small || iconCount > 4 ? " story--small" : "")}
        to={`/project/${story.project}/task/${story.id}`}
      >
        <ContextMenuTrigger id={`${preFix}story-context-${story.id}`}>
          <div className="story__body">
            <p>{story.json ? story.json.name : "..."}</p>
            <div className="story__icons">
              {story.json.flag && (
                <div className="story__icon">
                  <FaFlag color="#dc3545" />
                </div>
              )}
              {dependencies && dependencies.length > 0 && (
                <div className="story__icon" id={`${preFix}status-icon-dep-${story.id}`}>
                  <SiAdblock color="#dc3545" />
                  <Tooltip
                    placement="left"
                    isOpen={depIconOpen}
                    target={`${preFix}status-icon-dep-${story.id}`}
                    toggle={depToggle}
                  >
                    {`Dependencies: `}
                    {dependencies.map((d) => d)}
                  </Tooltip>
                </div>
              )}
              {story.json.complexity && (
                <div className="story__icon" id={`${preFix}status-icon-complexity-${story.id}`}>
                  <FaBrain color={complexityColor} />
                  <Tooltip
                    placement="left"
                    isOpen={complexityIconOpen}
                    target={`${preFix}status-icon-complexity-${story.id}`}
                    toggle={complexityToggle}
                  >
                    {`Complexity: ${story.json.complexity} / 10`}
                  </Tooltip>
                </div>
              )}
              {story.json.hours && (
                <div className="story__icon" id={`${preFix}status-icon-time-${story.id}`}>
                  <FaClock color={timeColor} />
                  <Tooltip
                    placement="left"
                    isOpen={timeIconOpen}
                    target={`${preFix}status-icon-time-${story.id}`}
                    toggle={timeToggle}
                  >
                    {`Estimated time: ${timeLabel}h`}
                  </Tooltip>
                </div>
              )}
              {story.json.moscow && (
                <>
                  <Badge color={moscowColors[story.json.moscow]} id={`${preFix}prio-${story.id}`}>
                    {story.json.moscow.toUpperCase()}
                  </Badge>
                  <Tooltip placement="left" isOpen={prioOpen} target={`${preFix}prio-${story.id}`} toggle={prioToggle}>
                    {moscowLabels[story.json.moscow]}
                  </Tooltip>
                </>
              )}
              {matchingCategory && (
                <Badge
                  style={{
                    backgroundColor: matchingCategory.color ? matchingCategory.color : "#6c757d",
                    color: matchingCategory.isLight ? "black" : "white",
                  }}
                >
                  {matchingCategory.name}
                </Badge>
              )}
              <div className="story__icon" id={`${preFix}status-icon-${story.id}`}>
                <StatusIcon color={statusColor} />
                <Tooltip
                  placement="left"
                  isOpen={statusIconOpen}
                  target={`${preFix}status-icon-${story.id}`}
                  toggle={statusToggle}
                >
                  {statusText}
                </Tooltip>
              </div>
              {assignee && (
                <>
                  <AvatarPic
                    noShadow
                    id={`${preFix}person-${story.id}`}
                    src={assignee.avatar}
                    style={{ width: "25px", height: "25px" }}
                  />
                  <Tooltip
                    placement="left"
                    isOpen={personOpen}
                    target={`${preFix}person-${story.id}`}
                    toggle={personToggle}
                  >
                    {`${assignee.displayName}`}
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          {chips}
        </ContextMenuTrigger>
      </Link>
      <ContextMenu id={`${preFix}story-context-${story.id}`}>
        {participants.length > 1 && <MenuItem onClick={assignSelfHandler}>Assign to me</MenuItem>}

        <MenuItem onClick={flagHandler}>{story.json.flag ? "Remove flag" : "Flag this task"}</MenuItem>
        {project?.json?.categories && project?.json?.categories.length > 0 && (
          <SubMenu title="Category">
            {project.json.categories.map((cat) => (
              <MenuItem key={cat.name} onClick={() => setCat(cat.name)}>
                {cat.name}
              </MenuItem>
            ))}
          </SubMenu>
        )}
        {project?.json?.chips && project?.json?.chips.length > 0 && (
          <SubMenu title="Add label">
            {project.json.chips.map((chip) => (
              <MenuItem key={chip} onClick={() => setChip(chip)}>
                {chip}
              </MenuItem>
            ))}
          </SubMenu>
        )}
        <MenuItem divider />
        <MenuItem data={{ foo: "bar" }} onClick={deleteHandler}>
          Delete task
        </MenuItem>
      </ContextMenu>
    </>
  );
};

export default Story;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGroup, Input, Row, Col } from "reactstrap";
import Loader from "../../UI/Loader/Loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./StoryPage.scss";
import CheckList from "./CheckList";
import Chips from "./Chips/Chips";
import StorySettings from "./StorySettings";
import Chat from "../../Chat/Chat";

class StoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      story: null,
      loading: true,
    };
  }

  componentDidMount() {
    if (this.props.match.params.stepSubId) {
      this.getStory();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.saveTimer);
    if (this.state.story) {
      this.props.saveStory(this.state.story);
    }
  }

  componentDidUpdate() {
    if (
      this.state.story &&
      this.props.match.params.stepSubId !== this.state.story.id
    ) {
      this.props.saveStory(this.state.story);
      clearTimeout(this.saveTimer);
      this.getStory();
      this.setState({ story: null, loading: true });
    }
  }

  fieldUpdateHandler = (event) => {
    const json = {
      ...this.state.story.json,
      [event.target.id]: event.target.value,
    };
    const story = { ...this.state.story, json };
    this.updateHandler(story);
  };

  storyJsonUpdateHandler = (json, rightAway) => {
    const story = { ...this.state.story, json };
    this.updateHandler(story, rightAway);
  };

  documentUpdateHandler = (event, editor) => {
    const data = editor.getData();
    //console.log({ event, editor, data });
    const json = {
      ...this.state.story.json,
      document: data,
    };
    const story = { ...this.state.story, json };
    this.updateHandler(story);
  };

  updateHandler = (story, rightAway = false) => {
    this.setState({ story });
    clearTimeout(this.saveTimer);
    if (rightAway) {
      this.props.saveStory(story);
    } else {
      this.saveTimer = setTimeout(() => {
        this.props.saveStory(story);
      }, 1000);
    }
  };

  setChips = (chips) => {
    const json = {
      ...this.state.story.json,
      chips,
    };
    const story = { ...this.state.story, json };
    this.updateHandler(story, true);
  };

  saveChat = (chat) => {
    const json = {
      ...this.state.story.json,
      chat,
    };
    const story = { ...this.state.story, json };
    this.updateHandler(story, true);
  };

  getStory = () => {
    const storyIndex = this.props.stories.findIndex(
      (n) => n.id === this.props.match.params.stepSubId
    );
    console.log();
    if (storyIndex > -1) {
      this.setState({
        story: JSON.parse(JSON.stringify(this.props.stories[storyIndex])),
        loading: false,
      });
    } else {
      this.setState({ loading: false });
      toast.warning("technical error");
    }
  };

  render() {
    const { story, loading } = this.state;
    const { chips, project, saveProject, participants, stories, user } =
      this.props;

    if (loading) {
      return <Loader />;
    }

    if (!story) {
      return (
        <div>
          <h2>Error: Can't find requested task</h2>
        </div>
      );
    }

    return (
      <div className="storypage">
        <FormGroup>
          <Input
            type="text"
            name="name"
            bsSize="lg"
            id="name"
            placeholder="Task title (short description)"
            value={story.json.name}
            onChange={this.fieldUpdateHandler}
          />
        </FormGroup>
        <Chips
          chips={story?.json?.chips || []}
          chipColors={project?.json?.chipColors}
          allChips={chips}
          setChips={this.setChips}
          project={project}
          story={story}
          saveProject={saveProject}
        />
        <Row>
          <Col lg="8">
            <CKEditor
              editor={ClassicEditor}
              data={story.json.document}
              className="main-editor"
              config={{
                toolbar: {
                  items: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "blockQuote",
                    "insertTable",
                    "|",
                    "undo",
                    "redo",
                  ],
                },
                table: {
                  contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                  ],
                },
                language: "en",
              }}
              /*
          onInit={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}*/
              onChange={this.documentUpdateHandler} /*
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}*/
            />
            <br />
            <h4>Task chat</h4>
            <Chat
              project={project}
              stories={stories}
              participants={participants}
              user={user}
              chat={story.json.chat}
              saveChat={this.saveChat}
            />
          </Col>
          <Col lg="4">
            <StorySettings
              story={story}
              updateHandler={this.storyJsonUpdateHandler}
              project={project}
              saveProject={saveProject}
              participants={participants}
              stories={stories}
            />
            <CheckList
              json={story.json}
              storyJsonUpdateHandler={this.storyJsonUpdateHandler}
            />
            <div className="storypage__info"></div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(StoryPage);
